import axios from 'axios';
import Inspection from './dto/Inspection';
import {authService} from "./AuthService";
import baseDataService from "./BaseDataService";
import InspectionItem from "./dto/InspectionItem";
import {settingsService} from "./SettingsService";
import {formatISO} from "date-fns";

class InspectionService {
    /**
     * @return {Promise<Inspection[]>}
     */
    async loadInspections() {
        const {data} = await axios.get('/inspections/active');
        return data.map((item) => Inspection.fromResponse(item));
    }

    async loadInspection(id) {
        const {data} = await axios.get(`/inspections/${id}`);
        return Inspection.fromResponse(data);
    }

    /**
     *
     * @param {InspectionItemTask}inspectionItemTask
     * @param {Inspection}inspection
     */
    async addInspectionItem(inspectionItemTask, inspection) {
        inspectionItemTask.inspectionItem.inspector = authService.user;
        inspectionItemTask.inspectionItem.measuringDevice = baseDataService.selectedMeasuringDevice.name;
        return await axios.post(`/inspections/${inspection.id}/items`, inspectionItemTask.inspectionItem.serialize(inspection.id)).then((data) => {
            if(settingsService.showServerResponse) {
                alert(JSON.stringify(data));
            }
            return InspectionItem.fromResponse(data.data);
        });
    }

    /**
     * @param {InspectionItemTask} inspectionItemTask
     * @param {Inspection} inspection
     */
    async patchInspectionItem(inspectionItemTask, inspection) {
        inspectionItemTask.inspectionItem.inspector = authService.user;
        inspectionItemTask.inspectionItem.updatedAt = formatISO(new Date());
        return await axios.put(`/inspections/${inspection.id}/items/${inspectionItemTask.inspectionItem.id}`, inspectionItemTask.inspectionItem.serialize(inspection.id))
            .then((data) => {
                if(settingsService.showServerResponse) {
                    alert(JSON.stringify(data));
                }
                return InspectionItem.fromResponse(data.data);
        });
    }

    /**
     * @param {InspectionItemTask} inspectionItemTask
     * @param {Inspection} inspection
     */
    async deleteInspectionItem(inspectionItemTask, inspection) {
        let response = await axios.delete(`/inspections/${inspection.id}/items/${inspectionItemTask.inspectionItem.id}`)

        if(settingsService.showServerResponse) {
            alert(JSON.stringify(response));
        }
        return response;
    }

    /**
     * @param facility {Facility}
     * @param inspector {User}
     * @param startDate {DateTime}
     * @return {Promise<Inspection>}
     */
    async createInspection(facility, inspector, startDate) {

        let facilityTypes = [];
        if(facility.facilityTypes.length > 0) {
            facilityTypes.push({
                id: facility.facilityTypes[0].id,
                name: facility.facilityTypes[0].name
            })
        }

        const inspection = {
            'Einrichtung': {
                'id': facility.id,
                'Name': facility.name,
                'Strasse': facility.street,
                'Hausnummer': facility.houseNumber,
                'Postleitzahl': facility.zipCode,
                'Ort': facility.city,
                'einrichtungsarts': facilityTypes
            },
            'Beginn': startDate.toFormat('yyyy-MM-dd'),
            'Kunde': facility.customer.id,
            'pruefleiter': inspector.id,
            'Eintraege': []
        };

        const {data} = await axios.post('/inspections', inspection);
        return Inspection.fromResponse(data);
    }

    canCompleteInspection(inspection) {
        if (!inspection) {
            return false;
        }
        if (authService.user.isAdmin) {
            return true;
        }
        return inspection.chiefInspector.id === authService.user.id;
    }

    async completeInspection(inspection, endDate, monthsUntilNextInspection, eMail = '') {
        if (this.canCompleteInspection()) {
            throw new Error('The current user has no permission to complete the inspection.');
        }

        const requestData = {
            'Ende': endDate.toFormat('yyyy-MM-dd'),
            'FristBisNaechstePruefung': monthsUntilNextInspection,
            'Email': eMail
        };

        const {data} = await axios.put(`/inspections/${inspection.id}/complete`, requestData);
        return Inspection.fromResponse(data);
    }

    async sendErrorReport(inspection, eMail = '') {
       const requestData = {
            'Email': eMail
        };

        const {data} = await axios.post(`/inspections/${inspection.id}/sendErrorReport`, requestData);
        return true;
    }
}

export const inspectionService = new InspectionService();
