import axios from 'axios';
import Customer from './dto/Customer';

class CustomerService {
    /**
     * @return {Promise<Customer[]>}
     */
    async loadCustomers() {
        const {data} = await axios.get('/customers');
        return data.map((item) => Customer.fromResponse(item));
    }
}

export const customerService = new CustomerService();