import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid,
    MenuItem, Paper,
    Select, Switch, TextField,
} from "@material-ui/core";

import {Close} from "@material-ui/icons";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {DateTime} from 'luxon';
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Inspection from '../../services/dto/Inspection';
import {inspectionService} from '../../services/InspectionService';
import {OverViewItem} from '../DutWizard/DutOverview';
import syncService from "../../services/async-api/SyncService";
import {Alert} from "@material-ui/lab";
import validator from "validator";

export default function CompleteInspectionDialog(props) {
    const [selectedDate, setSelectedDate] = useState(DateTime.now());
    const [selectedTimePeriodInMonths, setSelectedTimePeriodInMonths] = useState(12);
    const timePeriodsInMonths = [3, 6, 9, 12, 18, 24];
    const [isSaving, setIsSaving] = useState(false);
    const [didUpdateInspections, setDidUpdateInspections] = useState(false);
    const [mailRecipient, setMailRecipient] = useState('');
    const [mailRecipientActive, setMailRecipientActive] = useState(false);
    const [mailInvalid, setMailInvalid] = useState(false);
    const [amountOfFailedDuts, setAmountOfFailedDuts] = useState(0);
    const [errorRate, setErrorRate] = useState('');
    const [deviceCount, setDeviceCount] = useState(0);


    const handleClose = () => {
        props.handleClose();
    }

    const validateMail = () => {
        if(mailRecipient === '') {
            return true;
        }
        return validator.isEmail(mailRecipient);
    }

    useEffect(() => {
        if(!mailRecipientActive) {
            setMailRecipient('');
        }
    }, [mailRecipientActive]);

    useEffect(() => {
        if(props.open === true) {

            setDidUpdateInspections(false);
            inspectionService.loadInspection(syncService.currentInspection.id).then((inspection) => {
                syncService.changeInspection(inspection);
                setDidUpdateInspections(true);

                setDeviceCount(inspection.items.length)
                const aofd = inspection.items.filter((item: InspectionItem) => !item.passed).length
                setAmountOfFailedDuts(aofd);

                setErrorRate((aofd === 0 && inspection.items.length === 0) ? null : (aofd / inspection.items.length).toLocaleString(undefined, {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }));
            });


        } else {
            setDidUpdateInspections(false)
        }
    }, [props.open, props.inspection.items])


    const selectedTimePeriodInMonthsChanged = (event) => {
        setSelectedTimePeriodInMonths(event.target.value);
    }

    const completeInspection = () => {
        if(!validateMail()) {
            setMailInvalid(true);
        }
        setIsSaving(true);
        inspectionService.completeInspection(props.inspection, selectedDate, selectedTimePeriodInMonths, mailRecipient).then(() => {
            props.handleCompleted();
        }).finally(() => {
            setIsSaving(false);
        });
    }




    return (
        <Dialog onClose={handleClose} maxWidth={"xs"} fullWidth={true} open={props.open}>
            <DialogTitle>Prüfung abschließen
                <Button style={{position: "absolute", top: '5px', right: '5px'}} onClick={handleClose} color="primary">
                    <Close style={{fontSize: '35px'}}/>
                </Button>
            </DialogTitle>
            <DialogContent>
                {didUpdateInspections && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper style={{padding: '10px'}}>
                                <OverViewItem title={'Einrichtung'} value={props.inspection.facility.nameWithCustomer} nodivider />
                                <OverViewItem title={'Prüfbeginn'} value={props.inspection.begin.toLocaleString()}/>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper style={{padding: '10px'}}>
                                <OverViewItem title={'Geprüfte Geräte'} value={deviceCount} nodivider />
                                <OverViewItem title={'Fehlerhafte Geräte'} value={amountOfFailedDuts.toString()} />
                                <OverViewItem title={'Fehlerquote'} value={errorRate} />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper style={{padding: '10px'}}>
                                <OverViewItem title={'Prüfende'} value={ <KeyboardDatePicker
                                    fullWidth
                                    error={selectedDate === null}
                                    format={'dd.MM.yyyy'}
                                    value={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                />} nodivider />
                                <OverViewItem title={'Prüffrist'} value={
                                    <Select value={selectedTimePeriodInMonths} onChange={selectedTimePeriodInMonthsChanged}>
                                        {timePeriodsInMonths.map((timePeriodInMonths) => {
                                            return <MenuItem value={timePeriodInMonths}>{`${timePeriodInMonths} Monate`}</MenuItem>;
                                        })}
                                    </Select>
                                } />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Switch
                                checked={mailRecipientActive}
                                onChange={(evt) => {
                                    setMailRecipientActive(evt.target.checked);
                                }}
                            /> } label={<b>Mängelbericht per E-Mail versenden</b>}></FormControlLabel>

                            {mailRecipientActive && (
                                <Paper style={{padding: '10px'}}>
                                    <OverViewItem title={'Versand an'} value={
                                        <TextField value={mailRecipient} onChange={(evt) => {
                                            setMailRecipient(evt.target.value);
                                            setMailInvalid(false)
                                        }} error={!validateMail()}  placeholder={"E-Mail-Adresse"}></TextField>
                                    } nodivider></OverViewItem>
                                    {mailInvalid && (
                                        <Alert style={{marginTop: 8}} severity="error">Die E-Mail-Adresse ist ungültig</Alert>
                                    )}
                                </Paper>
                            )}

                        </Grid>
                    </Grid>
                )}
                {!didUpdateInspections && (
                    <Grid container style={{paddingTop: 20, paddingBottom: 20}} >
                        <Grid item xs={12} justify={"center"} direction={"row"} container>
                            <CircularProgress size={40} style={{marginBottom: 20}}></CircularProgress>
                        </Grid>
                        <Grid item xs={12} justify={"center"} direction={"row"} container>
                            Aktualisiere Prüflinge
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={!didUpdateInspections ||  isSaving || selectedDate === null} fullWidth color={"primary"} variant={"contained"} onClick={completeInspection}>
                    {isSaving ? <CircularProgress/> : 'Abschließen'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

CompleteInspectionDialog.propTypes = {
    inspection: PropTypes.instanceOf(Inspection).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCompleted: PropTypes.func.isRequired
}