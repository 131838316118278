export const NOTICE_TYPE_NOTICE = 'Hinweis';
export const NOTICE_TYPE_FLAW = 'Mangel';

export const NOTICE_TYPES = [NOTICE_TYPE_NOTICE, NOTICE_TYPE_FLAW];

export default class Notice {


    /**
     * @param id {number}
     * @param text {string}
     * @param type {string}
     */
    constructor(id, text, type) {
        this._id = id;
        this._text = text;
        this._type = type;
    }

    static fromResponse(data) {
        return new Notice(data.id, data['text'], data['Kategorie']);
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get text() {
        return this._text;
    }

    /**
     * @returns {string}
     */
    get type() {
        return this._type;
    }
}