export default class MeasuringDevice {
    /**
     * @param id {number}
     * @param name {string}
     * @param serialNumber {string}
     */
    constructor(id, name, serialNumber) {
        this._id = id;
        this._name = name;
        this._serialNumber = serialNumber;
    }

    static fromResponse(data) {
        return new MeasuringDevice(data.id, data['name'], '' + data['Seriennummer']);
    }

    serialize() {
        return {
            id: this.id,
            name: this.name,
            Seriennummer: this.serialNumber
        }
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @returns {string}
     */
    get serialNumber() {
        return this._serialNumber;
    }
}