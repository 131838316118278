import {DateTime} from 'luxon';
import Customer from './Customer';
import Facility from './Facility';
import InspectionItem from './InspectionItem';
import User from './User';

export default class Inspection {
    /**
     * @param {string} id
     * @param {DateTime} begin
     * @param {User} chiefInspector
     * @param {Customer} customer
     * @param {Facility} facility
     * @param {InspectionItem[]} items
     * @param otherInspections
     */
    constructor(id, begin, chiefInspector, customer, facility, items = [], otherInspections: Inspection[] = []) {
        this._id = id;
        this._begin = begin;
        this._chiefInspector = chiefInspector;
        this._customer = customer;
        this._facility = facility;
        this._items = items;
        this._otherInspections = otherInspections;
    }

    static fromResponse(data) {
        const customer = Customer.fromResponse(data['Kunde']);
        
        return new Inspection(
            data.id,
            DateTime.fromISO(data['Beginn']),
            User.fromResponse(data['pruefleiter']),
            customer,
            Facility.fromResponse(data['Einrichtung'], customer),
            data['Eintraege'].map((item) => InspectionItem.fromResponse(item))
        );
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {DateTime}
     */
    get begin() {
        return this._begin;
    }

    /**
     * @returns {User}
     */
    get chiefInspector() {
        return this._chiefInspector;
    }

    /**
     * @returns {Customer}
     */
    get customer() {
        return this._customer;
    }

    /**
     * @returns {Facility}
     */
    get facility() {
        return this._facility;
    }

    /**
     * @returns {InspectionItem[]}
     */
    get items() {
        return this._items;
    }

    get otherInspections(): [] {
        return this._otherInspections;
    }

    set otherInspections(value: Array) {
        this._otherInspections = value;
    }

    /**
     * @param {InspectionItem} item
     */
    removeItem(item) {
        let indexToRemove = -1;
        for (let i = 0; i < this.items.length; i++) {
            if(this.items[i].id === item.id || this.items[i].dutNumber === item.dutNumber) {
                indexToRemove = i;
            }
        }
        if(indexToRemove > -1) {
            this.items.splice(indexToRemove, 1);
        }
    }
}
