import Facility from './Facility';

export default class Customer {
    /**
     * @param id {number}
     * @param name {string}
     * @param facilities {Facility[]}
     */
    constructor(id, name, facilities = []) {
        this._id = id;
        this._name = name;
        this._facilities = facilities;
    }

    static fromResponse(data) {
        const customer = new Customer(data.id, data['Firmierung']);
        customer._facilities = data['Einrichtung'].map((item) => Facility.fromResponse(item, customer));
        return customer;
    }

    /**
     * @return {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @return {Facility[]}
     */
    get facilities() {
        return this._facilities;
    }
}