import {Button, Grid, makeStyles} from "@material-ui/core";
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Cached, Check} from "@material-ui/icons";
import '../../css/animations.css'
import syncService from "./SyncService";


const useStyles = makeStyles(() => ({
    icon: {
        marginRight: '5px',
        fill: "white"
    },
    spin: {
        animation: 'rotate 1.5s linear infinite',
        display:'block',
    },
    pulsatingGreen: {
        animationName: 'colorGreen',
        animationDuration: '4s',
        animationIterationCount: 'infinite',
        '&:hover': {
            backgroundColor: 'green',
        }

    },
    staticGreen: {
        backgroundColor: "green",
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    pulsatingRed: {
        animationName: 'colorRed',
        animationDuration: '4s',
        animationIterationCount: 'infinite',
        '&:hover': {
            backgroundColor: 'red',
        }
    }
}))


export default function SyncWidget(props) {

    const classes = useStyles();

    const [backgroundClass, setBackgroundClass] = useState(classes.pulsatingGreen);
    const [labelText, setLabelText] = useState('In Sync')
    const [icon, setIcon] = useState(<Check className={classes.icon}></Check>);
    const [currentStatus, setCurrentStatus] = useState(syncService.generateStatusUpdate())

    useEffect(() => {
        let mySubscriber = function (msg, data) {
            setCurrentStatus(data);
        };
        let token = syncService.subscribeForOnlineStatusUpdates(mySubscriber)
        return () => {
            syncService.unsubscribeFromOnlineStatusUpdates(token);
        }
    })

    useEffect(() => {
        if(!currentStatus.online) {
            setBackgroundClass(classes.pulsatingRed)
            setLabelText(`${currentStatus.outstanding} offen`)
            setIcon(<SyncProblemIcon className={classes.icon}></SyncProblemIcon>)
        } else if(currentStatus.outstanding === 0) {
            setBackgroundClass(classes.staticGreen)
            setLabelText('Sync');
            setIcon(<Check className={classes.icon}></Check>)
        } else {
            setBackgroundClass(classes.pulsatingGreen)
            setLabelText(`${currentStatus.outstanding} ausstehend`);
            setIcon(<Cached className={`${classes.icon} ${classes.spin}`}></Cached>)
        }
    }, [currentStatus, classes.icon, classes.pulsatingGreen, classes.spin, classes.staticGreen, classes.pulsatingRed]);

    return (
        <Button className={backgroundClass} style={{padding: 0, paddingLeft: 5, paddingRight: 5, textTransform: "none", cursor:"pointer !important"}} onClick={props.showTaskList}>
            <Grid container direction="row" alignItems="center" style={{padding: 0}}>
                    <div style={{height: '40px', lineHeight: '40px' , paddingLeft: '5px', paddingRight: '5px', display:'inline-block' ,color: 'white', whiteSpace: 'nowrap'}}>
                        {labelText}
                    </div>
                </Grid>
                {icon}
        </Button>
    )
}



SyncWidget.propTypes = {
    showTaskList: PropTypes.func.isRequired
}
