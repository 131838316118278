import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, makeStyles,
} from "@material-ui/core";
import DutChooser from "./DutChooser";
import {useEffect, useState} from "react";
import DutOptions, {DutOptionsFooter} from "./DutOptions";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InspectionItem from "../../services/dto/InspectionItem";
import DutNotices from "./DutNotices";
import InspectionItemTask, {TASK_ACTIONS} from "../../services/async-api/InspectionItemTask";
import {WizardTaskCollection, CREATIONS_STEPS} from "../../services/async-api/WizardTaskCollection";
import CloseIcon from "@material-ui/icons/Close";
import {DutActions} from "./DutActions";
import DutOverview, {DutOverviewFooter} from "./DutOverview";
import baseDataService from "../../services/BaseDataService";

let useStyles = makeStyles((theme) => (
    generateDialogCSS('80vw')
));

const steps =
    [
        {
            id: CREATIONS_STEPS.DUT_CHOOSE,
            title: 'Auswahl',
        },
        {
            id: CREATIONS_STEPS.DUT_OPTIONS,
            title: 'Informationen',
        },
        {
            id: CREATIONS_STEPS.DUT_NOTICES,
            title: 'Mängel- und Hinweise',
        },
        {
            id: CREATIONS_STEPS.DUT_ACTIONS,
            title: 'Empfehlungen',
        },
        {
            id: CREATIONS_STEPS.DUT_OVERVIEW,
            title: 'Abschluss'
        }
    ];

function findStep(id) {
    return steps.find((step) => {
        return step.id === id;
    });
}

function DutWizard(props) {



    let wizardTaskCollection = new WizardTaskCollection(null);

    const [taskCollection, setTaskCollection] = useState(wizardTaskCollection);
    const [maxWidth, setMaxWidth] = useState('xl')

    const [dutNumber, setDutNumber] = useState(null);

    useEffect(() => {
        if(props.open) {
            if(props.edit) {
                props.edit.originalInspectionItem = props.edit.inspectionItem.clone();

            }
            let tempInspectionItemTask = props.edit;
            let stepStart = CREATIONS_STEPS.DUT_OVERVIEW;
            if(!tempInspectionItemTask) {
                let inspectionItemClone = new InspectionItem();

                inspectionItemClone.dutNumber = baseDataService.lastDutNumber ? baseDataService.lastDutNumber+1 : null;
                inspectionItemClone.floor = baseDataService.lastFloor ? baseDataService.lastFloor : null;
                inspectionItemClone.room = baseDataService.lastRoom ? baseDataService.lastRoom : null;
                setDutNumber(inspectionItemClone.dutNumber);

                stepStart = CREATIONS_STEPS.DUT_CHOOSE
                tempInspectionItemTask = new InspectionItemTask(inspectionItemClone);
            }
            let wizardTaskCollection = new WizardTaskCollection([tempInspectionItemTask], stepStart);
            setTaskCollection(wizardTaskCollection)

        }
    }, [props.open, props.inspectionItem, props.edit]);


    useEffect(() => {
        if(taskCollection.step === CREATIONS_STEPS.DUT_OPTIONS || taskCollection.step === CREATIONS_STEPS.DUT_OVERVIEW) {
            //useStyles = makeStyles((theme) => (generateDialogCSS('600px')));
            setMaxWidth('xs');
        } else {
            //useStyles = makeStyles((theme) => (generateDialogCSS('80vw')));
            setMaxWidth('xl')
        }
    }, [taskCollection])

    /**
     * @param {WizardTaskCollection} taskCollection
     */
    const didUpdateTaskCollection = (taskCollection) => {

        console.log(taskCollection);
        setTaskCollection(taskCollection);
        if(taskCollection.step === CREATIONS_STEPS.DUT_FINISHED) {
            if(taskCollection.lastTask().action === TASK_ACTIONS.CREATE) {
                baseDataService.lastDutNumber = taskCollection.lastTask().dutNumber();
                baseDataService.lastFloor = taskCollection.lastTask().inspectionItem.floor;
                baseDataService.lastRoom = taskCollection.lastTask().inspectionItem.room;
            }
            props.onCreate(taskCollection);
        }
    }



    // const handleDutSelected = (dut) => {
    //     setCurrentStep(findStep(stepIdentifiers.step2DutOptions));
    // }
    //
    // const handleDutAction = (actionIdentifier, dutCount = 1) => {
    //     alert(actionIdentifier + " - " + dutCount);
    //     if(actionIdentifier === actionIdentifiers.approve || actionIdentifier === actionIdentifiers.approveAll) {
    //         alert('close');
    //     } else {
    //         setCurrentStep(findStep(stepIdentifiers.step3DutNotices));
    //     }
    // }


    const handleClose = () => {
        props.onAbort();

        // setInspectionItem(new InspectionItem());
        // setCurrentStep(findStep(stepIdentifiers.step1DutChooser));
    }

    const handleBackButtonClick = () => {
        setTaskCollection(taskCollection.stepBack());
    }

    /**
     * @param {WizardTaskCollection} taskCollection
     * @returns {JSX.Element|null}
     */
    const getStepComponent = (taskCollection) => {

        switch(taskCollection.step) {
            case CREATIONS_STEPS.DUT_CHOOSE:    return (<DutChooser taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            case CREATIONS_STEPS.DUT_OPTIONS:   return (<DutOptions taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            case CREATIONS_STEPS.DUT_NOTICES:   return (<DutNotices taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            case CREATIONS_STEPS.DUT_ACTIONS:   return (<DutActions taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            case CREATIONS_STEPS.DUT_OVERVIEW:  return (<DutOverview taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            default: return null;
        }
    }

    /**
     * @param {WizardTaskCollection} taskCollection
     * @returns {JSX.Element|null}
     */
    const getStepFooter = (taskCollection: WizardTaskCollection) => {
        switch(taskCollection.step) {
            case CREATIONS_STEPS.DUT_CHOOSE: return null
            case CREATIONS_STEPS.DUT_OPTIONS: return (<DutOptionsFooter taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            case CREATIONS_STEPS.DUT_NOTICES: return null;
            case CREATIONS_STEPS.DUT_OVERVIEW: return (<DutOverviewFooter onDelete={handleClose} taskCollection={taskCollection} onTaskCollectionChanged={didUpdateTaskCollection} />)
            default: return null;
        }
    }

    const classes = useStyles();
    return (
        <Dialog
            maxWidth={maxWidth}
            open={props.open}
            onClose={handleClose}
            fullScreen
            // PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            classes={{paper: classes.dialogPaper}}
            //style={{width: width}}
        >
            <DialogTitle id="choose-facility">
                { (taskCollection.step > CREATIONS_STEPS.DUT_CHOOSE && taskCollection.step !== CREATIONS_STEPS.DUT_OVERVIEW)  && (
                    <IconButton onClick={handleBackButtonClick} color={"primary"} aria-label="delete">
                        <ArrowBackIosIcon />
                    </IconButton>
                    )
                }
                <b>Schritt {taskCollection.step}: </b> {findStep(taskCollection.step)?.title}
                {taskCollection.step === 1 && dutNumber && (
                    <>
                        <br/>
                        <label style={{fontSize: '16px'}}>
                            Nächste Betriebsmittelnr.: <b>{dutNumber.toString().padStart(6, '0')}</b>
                        </label>
                    </>
                )}
                <Button style={{position: "absolute", top: '5px', right: '5px'}} onClick={handleClose} color="primary">
                    <CloseIcon style={{fontSize: '35px'}} />
                </Button>
            </DialogTitle >
            <DialogContent>
                {getStepComponent(taskCollection)}
            </DialogContent>
            <DialogActions style={{display: "block", padding: '5px', borderTop: '1px solid lightgrey'}}>
                {getStepFooter(taskCollection)}
            </DialogActions>
        </Dialog>
    )
}

function generateDialogCSS(width) {
    return {
        dialogPaper: {
            // minHeight: '80vh',
            // maxHeight: '80vh',
            // height: '80vh',
            // width: '80vw'
            //
        }
    }
}


DutWizard.prototype = {
    open: PropTypes.bool.isRequired,
    onAbort: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    edit:PropTypes.instanceOf(InspectionItemTask),
}

export default DutWizard;