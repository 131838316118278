import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Switch
} from "@material-ui/core";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {settingsService} from "../../services/SettingsService";

export default function SettingsDialog(props) {

    const [serverResponsePopup, setServerResponsePopup] = useState();

    useEffect(() => {
        if(props.open) {
            setServerResponsePopup(settingsService.showServerResponse);
        }

    }, [props.open])

    const changeServerResponsePopup = (value) => {
        setServerResponsePopup(value);
        settingsService.showServerResponse = value;
    }

    return (
        <Dialog onClose={props.onClose} open={props.open}>
            <DialogTitle>Einstellungen</DialogTitle>
            <DialogContent>
                <Container>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <b>Debug</b> Serverantworten als Popup-Nachricht anzeigen
                        </Grid>
                        <Grid item xs={2}>
                            <Switch checked={serverResponsePopup} onChange={(evt) => {changeServerResponsePopup(evt.target.checked)}} n></Switch>
                        </Grid>



                    </Grid>
                </Container>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}

SettingsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

