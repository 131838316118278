import User from './User';
import {formatISO} from "date-fns";

export default class InspectionItem {
    /**
     * @param id {number}
     * @param floor {string}
     * @param room {string}
     * @param dutNumber {string}
     * @param dut {string}
     * @param protectionClass {string}
     * @param passed {boolean}
     * @param recommendation {string}
     * @param action {string}
     * @param inspector {User}
     * @param measuringDevice {string}
     * @param comment {string}
     * @param createdAt {string|null}
     * @param updatedAt {string|null}
     */
    constructor(id, floor, room, dutNumber, dut, protectionClass, passed, recommendation, action, inspector, measuringDevice, comment, createdAt: string = null, updatedAt: string = null) {
        this._id = id;
        this._floor = floor;
        this._room = room;
        if(!isNaN(dutNumber)) {
            this._dutNumber = Number.parseInt(dutNumber);
        } else {
            this._dutNumber = dutNumber
        }
        this._dut = dut;
        if(protectionClass === 'I') {
            this._protectionClass = 1
        } else if (protectionClass === 'II') {
            this._protectionClass = 2
        } else {
            this._protectionClass = protectionClass;
        }

        this._passed = passed;
        this._recommendation = recommendation;
        this._action = action;
        this._inspector = inspector;
        this._measuringDevice = measuringDevice;
        this._comment = comment;
        if(createdAt) {
            this._createdAt = createdAt;
        } else {
            this._createdAt = formatISO(new Date());
        }
        if(updatedAt) {
            this._updatedAt = updatedAt;
        } else {
            this._updatedAt = formatISO(new Date())
        }
    }

    static fromResponse(data) {
        return new InspectionItem(
            data.id,
            data['Etage'],
            data['Raum'],
            data['Betriebsmittelnummer'],
            data['Betriebsmittel'],
            data['Schutzklasse'],
            data['Pruefergebnis'],
            data['Empfehlung'],
            data['Aktion'],
            data['pruefer'] ? User.fromResponse(data['pruefer']): null,
            data['Messgeraet']  ? data['Messgeraet'] : null,
            data['Kommentar'] ? data['Kommentar'] : null,
            data['created_at'],
            data['updated_at']
        );
    }

    serialize(inspectionId, withDates = false) {
        let sInspection = {
            Etage: this.floor,
            Raum: this.room,
            Betriebsmittelnummer: this.dutNumber,
            Betriebsmittel: this.dut,
            Schutzklasse: this.protectionClass === 1 ? 'I' : 'II',
            Pruefergebnis: this.passed,
            Empfehlung: this.recommendation,
            Aktion: this.action,
            Kommentar: this.comment,
            pruefer: this.inspector ? this.inspector.serialize() : null,
            Messgeraet: this.measuringDevice,
            Pruefung: inspectionId
        }
        if(withDates) {
            sInspection['created_at'] = this._createdAt;
            sInspection['updated_at'] = this._updatedAt;
        }
        if(this.id) {
            sInspection.id = this.id;
        }
        return sInspection;
    }

    /**
     * @return {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {string}
     */
    get floor() {
        return this._floor;
    }

    /**
     * @return {string}
     */
    get room() {
        return this._room;
    }

    /**
     * @return {string}
     */
    get dutNumber() {
        return this._dutNumber;
    }

    /**
     * @return {string}
     */
    get dutNumberFormatted() {
        return this._dutNumber.toString().padStart(6, '0');
    }

    /**
     * @return {string}
     */
    get dut() {
        return this._dut;
    }

    /**
     * @return {string}
     */
    get protectionClass() {
        return this._protectionClass;
    }

    /**
     * @return {boolean}
     */
    get passed() {
        return this._passed;
    }

    /**
     * @return {string}
     */
    get recommendation() {
        return this._recommendation;
    }

    /**
     * @return {string}
     */
    get action() {
        return this._action;
    }

    /**
     * @return {User}
     */
    get inspector() {
        return this._inspector;
    }

    /**
     * @return {string}
     */
    get measuringDevice() {
        return this._measuringDevice;
    }

    /**
     * @return {string}
     */
    get comment() {
        return this._comment;
    }

    /**
     * @return {string}
     */
    get location() {
        return `Etage: ${this.floor} Raum: ${this.room}`;
    }


    set id(value: number) {
        this._id = value;
    }

    set floor(value: string) {
        this._floor = value;
    }

    set room(value: string) {
        this._room = value;
    }

    set dutNumber(value: string) {
        this._dutNumber = value;
    }

    set dut(value: string) {
        this._dut = value;
    }

    set protectionClass(value: string) {
        this._protectionClass = value;
    }

    set passed(value: boolean) {
        this._passed = value;
    }

    set recommendation(value: string) {
        this._recommendation = value;
    }

    set action(value: string) {
        this._action = value;
    }

    set inspector(value: User) {
        this._inspector = value;
    }

    set measuringDevice(value: string) {
        this._measuringDevice = value;
    }

    set comment(value: string) {
        this._comment = value;
    }


    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get updatedAt(): string {
        return this._updatedAt;
    }

    set updatedAt(value: string) {
        this._updatedAt = value;
    }

    /**
     * @return {string}
     */
    toString() {
        return `${this.dutNumberFormatted} - ${this.dut} (${this.protectionClass})`;
    }

     clone() {
        return new InspectionItem(this.id,this.floor, this.room, this.dutNumber, this.dut, this.protectionClass, this.passed, this.recommendation, this.action, null, this.measuringDevice, this.comment, this.createdAt, this.updatedAt)
     }

     equals(inspectionItem) {
        if(inspectionItem === null) {
            return false;
        }
        if(this.id !== inspectionItem.id) {
            return false;
        }
        if(this.floor !== inspectionItem.floor) {
            return false;
        }
        if(this.room !== inspectionItem.room) {
            return false;
        }
        if(this.dutNumber !== inspectionItem.dutNumber) {
            return false;
        }
        if(this.protectionClass !== inspectionItem.protectionClass) {
            return false;
        }
        if(this.dut !== inspectionItem.dut) {
            return false;
        }
        if(this.passed !== inspectionItem.passed) {
            return false;
        }
        if(this.recommendation !== inspectionItem.recommendation) {
            return false;
        }
        if(this.comment !== inspectionItem.comment) {
            return false;
        }
        if(this.action !== inspectionItem.action) {
            return false;
        }


        return true;
     }

}
