import Color from "color";
import {List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {useEffect} from "react";


export default function ColoredList(props) {
    let color = Color(props.color).alpha(0.4);
    const useStyles = makeStyles((theme) => ({
        listItem: {
            transition: 'box-shadow .3s',
            //background: 'linear-gradient(180deg, ' + color.toString() + ' 0%, ' + color.darken(0.3).toString() + ' 100%)',
            background: color.lighten(0.4).toString(),
            border: '1px solid ' + color.darken(0.3).toString(),
            borderRadius: '3px',
            marginBottom: '5px',
            '&:hover': {
                //border: '1px solid ' + color.darken(0.8),
                boxShadow: '0 0 11px ' + color.alpha(0.6).darken(0.3),
                fontWeight: 'bold',
                background: color.darken(0.2).toString(),
                //background: Color(props.color).darken(0.2).toString()
            }
        },
        listItemText: {
            fontSize: 'large',
            color: Color(props.color).darken(0.6).toString()
            // '&:hover': {
            //     fontWeight: 'bold'
            // }
        }

    }));

    useEffect(() => {

    }, [props.selectedValue])

    const classes = useStyles();
    let style = {};

    let list = props.values.map((value, index) => {

        if(value === props.selectedValue) {
            style = {borderWidth: '4px'};
        } else {
            style = {borderWidth: '1px'};
        }
        return (
            <ListItem key={index} button onClick={() => {props.callback(value)}} style={style}  className={classes.listItem}>
                <ListItemText classes={{primary: classes.listItemText}}>{value}</ListItemText>
            </ListItem>
        )
    });
    return (
        <List>
            {props.freeTextEntry && (
                <ListItem button onClick={() => {
                    if(props.onFreeTextEntrySelected) {
                        props.onFreeTextEntrySelected();
                    }
                }} style={{fontStyle: 'italic'}}  className={classes.listItem}>
                    <ListItemText classes={{primary: classes.listItemText}}>Freien Text eingeben...</ListItemText>
                </ListItem>
            )}
            {list}
        </List>
    )
}

ColoredList.propTypes = {
    callback: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    freeTextEntry: PropTypes.bool,
    onFreeTextEntrySelected: PropTypes.func,
    selectedValue: PropTypes.string,


}