import {Button, ButtonGroup, Grid, InputAdornment, makeStyles, TextField, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DEFAULT_FLOORS} from "../../services/BaseDataService";
import PropTypes from "prop-types";
import InspectionItemTask, {APPROVAL_TYPES} from "../../services/async-api/InspectionItemTask";
import InspectionItem from "../../services/dto/InspectionItem";
import {CREATIONS_STEPS, WizardTaskCollection} from "../../services/async-api/WizardTaskCollection";
import syncService from "../../services/async-api/SyncService";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        // margin: theme.spacing(2),
        // marginTop: theme.spacing(4),
        // padding: theme.spacing(1),
    },
    // icon: {
    //     fontSize: 36
    // },
    disabledButton: {
        backgroundColor: 'lightgray !important;',
    },
    checkButton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'darkgreen',
        }
    },
    dutNumberInputAdornment: {
        marginRight: '0',
        '.Mui-disabled & .MuiTypography-colorTextSecondary': {
            color: 'rgba(0, 0, 0, 0.38)'
        }
    }
}));

function DutOptions(props) {
    const classes = useStyles();
    let inspectionItem = props.taskCollection.firstTask()?.inspectionItem;

    const [dutName] = useState(inspectionItem?.dut)
    const [protectionClass, setProtectionClass] = useState(inspectionItem?.protectionClass);
    const [dutNumberFrom, setDutNumberFrom] = useState(inspectionItem?.dutNumber ? inspectionItem.dutNumber : '');
    const [dutNumberTo, setDutNumberTo] = useState(dutNumberFrom + props.taskCollection.taskCount() - 1);
    const [dutCount, setDutCount] = useState(props.taskCollection.taskCount());
    const [floor, setFloor] = useState(inspectionItem?.floor ? inspectionItem?.floor : '');
    const [room, setRoom] = useState(inspectionItem?.room ? inspectionItem?.room : '');

    const [editMode] = useState(props.taskCollection.firstTask()?.originalInspectionItem != null);

    const changeProtectionClass = (protectionClass) => {
        setProtectionClass(protectionClass);
    };

    const changeDutNumber = (evt) => {
        let numberValue = evt.target.value;
        if (numberValue.length > 6) {
            return;
        }
        if (numberValue) {
            numberValue = parseInt(numberValue);
        }
        setDutNumberFrom(numberValue);
    }

    const changeDutCount = (value) => {
        if (value === 0 || value === '0') {
            value = 1;
        }
        if (value < 0) {
            setDutCount(1);
        } else {
            if(isNumeric('' +value)) {
                setDutCount(Number.parseInt(value))
            } else {
                setDutCount('');
            }
        }
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const changeFloor = (evt) => {
        setFloor(evt.target.value);
    }

    const changeFloorAutocomplete = (evt, value) => {
        setFloor(value);
    }

    const changeRoom = (evt) => {
        setRoom(evt.target.value);
    }

    const dutNumberPrefix = (dutNumber) => {
        const missingChars = !dutNumber || !dutNumber.toString().length ? 5 : 6 - dutNumber.toString().length;

        if (missingChars <= 0) {
            return '';
        }

        return (new Array(missingChars + 1)).join('0');
    }

    useEffect(() => {
        if(isNumber(dutCount) && isNumber(dutNumberFrom)) {
            console.log('change in Options');
            let tc = props.taskCollection.clone();

            if(tc.tasks.length !== 1 || dutCount !== 1) {
                let tasks = [];
                for (let i = dutNumberFrom; i < (dutNumberFrom + dutCount); i++) {
                    let item = new InspectionItemTask(new InspectionItem(null, floor, room, i, dutName, protectionClass));
                    console.log(item);
                    tasks.push(item);
                }
                tc.tasks = tasks;
            } else {
                tc.firstTask().inspectionItem.floor = floor;
                tc.firstTask().inspectionItem.room = room;
                tc.firstTask().inspectionItem.dutNumber = dutNumberFrom;
                tc.firstTask().inspectionItem.protectionClass = protectionClass
            }
            props.onTaskCollectionChanged(tc);

        }
        //eslint-disable-next-line
    }, [protectionClass, floor, room, dutNumberFrom, dutCount, dutName])

    const isNumber = require('is-number');
    useEffect(() => {
        // console.log(props.taskCollection.firstTask())
        if (isNumber(dutNumberFrom) && isNumber(dutCount)) {
            setDutNumberTo(parseInt(dutNumberFrom) + parseInt(dutCount) - 1);
        } else {
            setDutNumberTo('');
        }
    }, [dutNumberFrom, dutCount]);



    return (
        <>
            <Grid container>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled label={"Betriebsmittel"} value={dutName}></TextField>
                    </Grid>
                </Grid>

                {/*<FormHeader name={'Schutzklasse'}></FormHeader>*/}
                <Grid item container xs={12} style={{marginTop: 10}} direction="row" alignItems="center"
                      justifyContent="center">
                    <Grid item>
                        <ProtectionClassButton selected={protectionClass === 1} onSelection={changeProtectionClass}
                                               protectionClass={1}/>
                        <ProtectionClassButton selected={protectionClass === 2} onSelection={changeProtectionClass}
                                               protectionClass={2}/>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <FormHeader name={`Prüflinge (${dutCount} Stück)`}></FormHeader>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField variant={"outlined"} disabled={editMode} error={!isNumber(dutNumberFrom)} fullWidth value={dutNumberFrom} type={"number"}
                                       onChange={changeDutNumber} label={'Von'} InputProps={{
                                           startAdornment: <InputAdornment position={'start'} className={classes.dutNumberInputAdornment}>{dutNumberPrefix(dutNumberFrom)}</InputAdornment>
                            }}></TextField>
                            {/*<label style={{lineHeight: '70px'}}> - </label>*/}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant={"filled"} disabled error={!isNumber(dutNumberTo)} fullWidth value={dutNumberTo}
                                       label={'Bis'} InputProps={{
                                startAdornment: <InputAdornment position={'start'} className={classes.dutNumberInputAdornment}>{dutNumberPrefix(dutNumberTo)}</InputAdornment>
                            }}></TextField>
                        </Grid>
                            <Grid xs={6} item>
                                <TextField variant={"outlined"} disabled={editMode} fullWidth error={!isNumber(dutCount)} label={"Anzahl"} onChange={(evt) => {
                                    changeDutCount(evt.target.value)
                                }} type={"number"} value={dutCount} /*style={{marginRight: 20}}*/ ></TextField>

                            </Grid>
                            <Grid xs={6} item container direction="row" alignItems="center"
                                  justifyContent="center">
                                <ButtonGroup disabled={editMode} style={{marginTop: 20}}>
                                <Button onClick={() => {
                                    let tempDutCount = dutCount;
                                    if (!tempDutCount) {
                                        tempDutCount = 0;
                                    }
                                    changeDutCount(tempDutCount - 1);
                                }}>-</Button>
                                <Button onClick={() => {
                                    let tempDutCount = dutCount;
                                    if (!tempDutCount) {
                                        tempDutCount = 0;
                                    }
                                    changeDutCount(tempDutCount + 1);
                                }}>+</Button>
                                </ButtonGroup>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <FormHeader name={'Prüfungsort'}></FormHeader>
                    <Grid item container xs={12} spacing={2}>
                        <Grid container item xs={6}>
                            {/*<TextField fullWidth value={floor} onChange={changeFloor} label={'Etage'} style={{marginRight: 20}}></TextField>*/}
                            <Autocomplete
                                options={DEFAULT_FLOORS.map((option) => option.title)}
                                value={floor}
                                inputValue={floor}
                                onInputChange={changeFloor}
                                onChange={changeFloorAutocomplete}
                                style={{width: '100%', display: 'inline-block'}}
                                renderInput={(params) =>
                                    <TextField  error={!floor} {...params} label="Etage" variant="outlined"
                                               InputProps={{...params.InputProps, type: 'search'}}/>
                                }
                                freeSolo={true}
                                disableClearable={true}
                            >
                            </Autocomplete>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant={"outlined"} error={!room} fullWidth value={room} onChange={changeRoom}
                                       label={'Raum'}></TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

function FormHeader(props) {
    return (
        <Grid item xs={12}>
            <Typography style={{marginTop: 5, marginBottom: 0}} variant={'h6'}>{props.name}</Typography>
        </Grid>
    )
}

function ProtectionClassButton(props) {
    return (
        <Button style={{padding: 15, margin: 10}} color={"primary"} onClick={() => {
            props.onSelection(props.protectionClass);
        }} variant={props.selected ? "contained" : 'outlined'}>
            <div>
                <Typography style={{fontSize: 'smaller', fontWeight: 'bold'}}>Schutzklasse</Typography>
                <Typography style={{
                    fontSize: 'large',
                    fontWeight: 'bold'
                }}>{props.protectionClass === 2 ? 'II' : 'I'}</Typography>
            </div>
        </Button>
    )
}


export function DutOptionsFooter(props) {

    const [error, setError] = useState(true);
    const [showDenyButton, setShowDenyButton] = useState(false)

    const classes = useStyles();
    useEffect(() => {
        let iit = props.taskCollection.firstTask()?.inspectionItem;
        if (!iit?.protectionClass || !iit?.dutNumber || !iit?.floor || !iit?.room) {
            setError(true);
            setShowDenyButton(false);
        } else {
            setError(false);
            if (props.taskCollection.taskCount() === 1) {
                setShowDenyButton(true);
            } else {
                setShowDenyButton(false);
            }
        }
    }, [props.taskCollection])


    const handleApproveItem = (approvalType) => {
        let tc = props.taskCollection.clone();
        //Check duplicate DutNumber
        if(!tc.firstTask().originalInspectionItem || tc.firstTask().originalInspectionItem?.dutNumber !== tc.firstTask().inspectionItem.dutNumber) {
            for (let i = 0; i < tc.tasks.length; i++) {
                let task = tc.tasks[i];
                let duplicate = syncService.findDuplicateDutNumber(task);
                if(duplicate) {
                    //TODO: Die Fehlermeldung könnet man später ggf. noch schöner anzeigen!
                    alert(`Die Bestriebsmittelnummer ${task.inspectionItem.dutNumberFormatted} wurde bereits verwendet!`);
                    return;
                }
            }
        }

        tc.tasks.forEach((task, index) => {
            task.inspectionItem.passed = approvalType === APPROVAL_TYPES.APPROVE;
        });

        if (approvalType === APPROVAL_TYPES.DENY) {
            tc.step = CREATIONS_STEPS.DUT_NOTICES;
        } else {
            tc.firstTask().inspectionItem.recommendation = null;
            tc.firstTask().inspectionItem.action = null;
            tc.firstTask().inspectionItem.comment = null;
            if(tc.firstTask().originalInspectionItem) {
                tc.step = CREATIONS_STEPS.DUT_OVERVIEW;
            } else {
                tc.step = CREATIONS_STEPS.DUT_FINISHED;
            }

        }
        props.onTaskCollectionChanged(tc)
    }

    return (
        <Grid container item xs={12} justifyContent={"space-between"} direction={"row"} alignContent={"center"}>
            {showDenyButton && (
                <Grid item xs={4} style={{padding: '5px'}}>
                    <Button fullWidth disabled={props.taskCollection.taskCount() !== 1 || error}
                            className={classes.actionButtons}
                            classes={{disabled: classes.actionButtons}}
                            variant={"contained"}
                            color="secondary"
                            component="span"
                            onClick={() => {
                                handleApproveItem(APPROVAL_TYPES.DENY)
                            }}
                    >
                        <CloseIcon className={classes.icon}/>
                    </Button>
                </Grid>
            )}

            <Grid item xs={showDenyButton ? 4 : 12} style={{padding: '5px'}}>
                <Button fullWidth
                        disabled={props.taskCollection.taskCount() < 1 || error}
                        className={classes.actionButtons}
                        classes={{contained: classes.checkButton, disabled: classes.disabledButton}}
                        variant={"contained"}
                        color="primary"
                    // style={{backgroundColor: 'green'}}
                        component="span"
                        onClick={() => {
                            handleApproveItem(APPROVAL_TYPES.APPROVE);
                        }}
                >
                    {(props.taskCollection.taskCount() > 1 ? <DoneAllIcon className={classes.icon}/> :
                        <DoneIcon className={classes.icon}/>)}
                </Button>
            </Grid>
        </Grid>
    )
}

DutOptions.propTypes = {
    taskCollection: PropTypes.instanceOf(WizardTaskCollection).isRequired,
    onTaskCollectionChanged: PropTypes.func.isRequired
}

export default DutOptions;
