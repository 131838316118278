import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

interface ConfirmDialogProps {
    open: boolean,
    title: string,
    message: React.ReactNode,
    handleConfirm: (confirm: boolean) => void,
}
export const ConfirmDialog : React.FC<ConfirmDialogProps> = ({open, message, title, handleConfirm}) => {

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                <Button style={{float: 'left'}} onClick={() => {
                    handleConfirm(false)
                }}>
                    Nein
                </Button>
                <Button variant={"contained"} color={"primary"} onClick={() => {
                    handleConfirm(true)
                }}>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}