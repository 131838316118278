import {
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import {Cancel, CheckCircle} from "@material-ui/icons";
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import baseDataService from '../services/BaseDataService';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '15rem',
        maxWidth: '50%',
        alignSelf: 'center',
        textAlign: 'center'
    }
}));

function Loader(props) {
    const loadingStates = {
        loading: 1,
        finished: 2,
        error: 3
    };
    const [retry, setRetry] = useState(false);
    const [loadingState, setLoadingState] = useState(loadingStates.loading)
    // const [dataLoaders, setDataLoaders] = useState({
    //     dutCategories: {label: 'Betriebsmittel', loader: baseDataService.loadDutCategories},
    //     notices: {label: 'Hinweistexte', loader: baseDataService.loadNotices},
    //     measuringDevices: {label: 'Messgeräte', loader: baseDataService.loadMeasuringDevices}
    // });
    const classes = useStyles();

    const load = () => {
       setRetry(false);
       setLoadingState(loadingStates.loading);
       baseDataService.loadBaseData().then(() => {
            setLoadingState(loadingStates.finished)
            props.onLoaded();
       }).catch((error) => {
           setLoadingState(loadingStates.error)
           setRetry(true);
           console.error(error);
       });
    }
    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ProgressIndicator = (props) => {
        switch (props.state) {
            case loadingStates.loading:
                return (<CircularProgress/>);
            case loadingStates.finished:
                return (<CheckCircle style={{color: 'green'}}/>);
            case loadingStates.error:
                return (<Cancel color={'error'}/>);
            default:
                return '';
        }
    }



    return (
        <div className={classes.root}>
            <List>
                <ListItem >
                    <ListItemText primary={'Lade Basisdaten'}/>
                    <ListItemSecondaryAction>
                        <ProgressIndicator state={loadingState}/>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {retry && (<Button onClick={load} variant={'contained'} color={'primary'}>Erneut versuchen</Button>)}
        </div>
    );
}

Loader.propTypes = {
    onLoaded: PropTypes.func.isRequired
}

export default Loader;