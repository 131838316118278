import {
    Avatar, Badge,
    Fab, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles, Menu, MenuItem
} from '@material-ui/core';
import {
    Add,
    DeleteOutline,
    MailOutline,
    YoutubeSearchedFor
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import baseDataService from '../../services/BaseDataService';
import DutWizard from "../DutWizard/DutWizard";
import React, {Fragment, useEffect, useState} from "react";
import {WizardTaskCollection} from "../../services/async-api/WizardTaskCollection";
import syncService from "../../services/async-api/SyncService";
import {TaskStatus} from "../../services/async-api/TaskList";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {TASK_ACTIONS} from "../../services/async-api/InspectionItemTask";
import CompleteInspectionDialog from './CompleteInspectionDialog';
import SearchDutDialog from "./SearchDutDialog";
import ErrorReportDialog from "./ErrorReportDialog";
import {inspectionService} from "../../services/InspectionService";
import SwitchInspectionDialog from "./SwitchInspectionDialog";

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: '3px',
        border: '1px solid lightgrey',
        marginBottom: '5px'
    },
    listItemIO: {
        // background: 'linear-gradient(184deg, rgba(30,103,0,.9) 0%, rgba(32,184,0,.9) 100%)',
        backgroundColor: '#f6fff3',
        border: '2px solid #67a153',
    },
    listItemNIO: {
        //background: 'linear-gradient(184deg, rgba(255,0,0,1) 0%, rgba(133,0,0,1) 100%);'
        backgroundColor: '#ffeaea',
        border: '2px solid red',
    }
}));

function InspectionList(props) {
    const limit = 20;

    const [showWizard, setShowWizard] = useState(false);
    const [inspectionItemTasks, setInspectionsItemTasks] = useState(syncService.lastNItemsOfCurrentInspector(limit));
    const [editInspectionItemTask, setEditInspectionItemTask] = useState(null);
    const [errorReportDialogOpen, setErrorReportDialogOpen] = useState(false);
    const [inspectionSwitcherOpen, setInspectionsSwitcherOpen] = useState(false);

    const closeWizard = () => {
        setEditInspectionItemTask(null);
        setShowWizard(false);
    }

    const handleDidFindDut = (taskItem) => {
        setEditInspectionItemTask(taskItem.clone());
        setShowWizard(true);
        props.onCloseSearchDialog();
    }

    const editWizard = (item) => {
        setEditInspectionItemTask(item.clone());
        setShowWizard(true);
    }

    useEffect(() => {
        if(props.showErrorList) {
            inspectionService.loadInspection(syncService.currentInspection.id).then((inspection) => {
                syncService.changeInspection(inspection).then(() => {
                    setInspectionsItemTasks(syncService.allNonPassedItems());
                });
            });
            setInspectionsItemTasks(syncService.allNonPassedItems());
        } else {
            setInspectionsItemTasks(syncService.lastNItemsOfCurrentInspector(limit));
        }
    },[props.inspection, props.showErrorList])

    useEffect(() => {
        let mySubscriber = function (msg, data) {
            if(props.showErrorList) {
                setInspectionsItemTasks(syncService.allNonPassedItems());
            } else {
                setInspectionsItemTasks(syncService.lastNItemsOfCurrentInspector(limit));
            }
        };
        let token = syncService.subscribeForOnlineStatusUpdates(mySubscriber)
        return () => {
            syncService.unsubscribeFromOnlineStatusUpdates(token);
        }
    })

    /**
     * @param {WizardTaskCollection} wizardTaskCollection
     */
    const createInspectionItem = (wizardTaskCollection : WizardTaskCollection) => {
        // alert(JSON.stringify(wizardTaskCollection));
        syncService.submitSyncTasks(wizardTaskCollection.tasks);
        closeWizard();
        if(wizardTaskCollection.firstTask().action === TASK_ACTIONS.CREATE) {
            setTimeout(() => {
                setShowWizard(true)
            }, 300)
        }
    }

    const onDelete = (task) => {
        let shouldDelete = window.confirm(`Wollen Sie den Eintrag mit Betriebsmittelnummer: ${task.inspectionItem.dutNumberFormatted} wirklich löschen?`);
        if(shouldDelete) {
            task.action = TASK_ACTIONS.DELETE;
            syncService.submitSyncTasks(task);
        }
    }

    const classes = useStyles();
    return (
        <>
            <List style={{padding: '5px'}}>
                {inspectionItemTasks.map((task) => {
                    let item = task.inspectionItem;
                    let listClass = item.passed ? classes.listItemIO : classes.listItemNIO;
                    // console.log(item.dut);
                    // console.log(baseDataService.findDutImageByName(item.dut).url);
                    return (
                        <ListItem onClick={() => {
                            //syncService.debugOutputTaskStorage();
                        }} className={`${classes.listItem} ${listClass}`} key={item.id}>
                            <ListItemAvatar>
                                <Avatar variant={'square'} alt={item.dut} style={{width: '55px', height:'55px',marginRight:'10px'}}
                                    src={baseDataService.findDutImageByName(item.dut).image()}/>
                            </ListItemAvatar>
                            <ListItemText primary={item.toString()} secondary={item.location}>
                            </ListItemText>
                            <TaskStatus status={task.status} size={40}/>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                    <Fragment>
                                        <IconButton {...bindTrigger(popupState)} style={{marginRight: 25}}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu {...bindMenu(popupState)}>
                                            <MenuItem onClick={() => {
                                                popupState.close();
                                                editWizard(task);
                                            }}><EditOutlinedIcon style={{paddingRight: 10}}/> Bearbeiten</MenuItem>
                                            <MenuItem  onClick={() => {
                                                popupState.close();
                                                onDelete(task);
                                            }}>
                                                <DeleteOutline style={{paddingRight: 10}}/>Löschen
                                            </MenuItem>
                                        </Menu>
                                    </Fragment>
                                )}
                            </PopupState>
                        </ListItem>
                    )
                })}
            </List>

            <DutWizard open={showWizard} onAbort={closeWizard} onCreate={createInspectionItem} edit={editInspectionItemTask}  />
            <SearchDutDialog didFindDut={handleDidFindDut} open={props.showSearchDialog} handleClose={props.onCloseSearchDialog}/>
            <CompleteInspectionDialog
                inspection={props.inspection}
                open={props.showCompleteInspectionDialog}
                handleClose={props.onCloseCompleteInspectionDialog}
                handleCompleted={props.onCompletedInspection}
            />
            <ErrorReportDialog inspection={props.inspection} open={errorReportDialogOpen} handleClose={() => {
                setErrorReportDialogOpen(false)
            }} handleCompleted={() => {
                alert('completed');
            }}></ErrorReportDialog>

            {!props.showErrorList && (
                <>
                    <Fab color={'primary'} className={'bottomRight'} onClick={() => setShowWizard(true)}>
                        <Add/>
                    </Fab>
                    {props.inspection.otherInspections.length > 1 && (
                        <Fab color={'primary'} className={'bottomLeft'} onClick={
                            () => {
                                setInspectionsSwitcherOpen(true)
                            }
                        }>
                            <Badge  color={'secondary'} badgeContent={props.inspection.otherInspections.length}><YoutubeSearchedFor fontSize={'large'}/></Badge>
                        </Fab>
                    )}
                    <SwitchInspectionDialog open={inspectionSwitcherOpen} inspection={props.inspection} didSelectInspection={(inspection) => {
                        setInspectionsSwitcherOpen(false);
                        props.onSwitchInspection(inspection);
                    }}></SwitchInspectionDialog>
                </>
            )}

            {props.showErrorList && (
                <Fab color={'secondary'} className={'bottomRight'} onClick={() => setErrorReportDialogOpen(true)}>
                    <MailOutline/>
                </Fab>
            )}
        </>
    );
}

InspectionList.propTypes = {
    inspection: PropTypes.object.isRequired,
    showSearchDialog: PropTypes.bool.isRequired,
    onCloseSearchDialog: PropTypes.func.isRequired,
    showCompleteInspectionDialog: PropTypes.bool.isRequired,
    onCloseCompleteInspectionDialog: PropTypes.func.isRequired,
    onCompletedInspection: PropTypes.func.isRequired,
    showErrorList: PropTypes.bool.isRequired,
    onSwitchInspection: PropTypes.func.isRequired
}

export default InspectionList;
