import LuxonUtils from '@date-io/luxon';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {authService} from './services/AuthService';
import baseDataService from "./services/BaseDataService";
// import serviceWorker from './serviceWorker'

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
axios.defaults.timeout = 30000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use((config) => {
        if (authService.authToken) {
            config.headers.Authorization = `Bearer ${authService.authToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        if(!error.request.responseURL.endsWith('app/login')) {
            baseDataService.clearLocalStorage();
            authService.logout();
            window.location.reload();
        }
    } else {
        return Promise.reject(error);
    }
});

// serviceWorker.register();

function delay(t, v) {
    return new Promise(function(resolve) {
        setTimeout(resolve.bind(null, v), t)
    });
}

//eslint-disable-next-line
Promise.prototype.delay = function(t) {
    return this.then(function(v) {
        return delay(t, v);
    });
}

ReactDOM.render(
    <React.StrictMode>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <App/>
        </MuiPickersUtilsProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
