import FacilityType from "./FacilityType";

export default class Facility {
    /**
     * @param id {number}
     * @param name {string|null}
     * @param street {string}
     * @param houseNumber {string}
     * @param zipCode {string}
     * @param city {string}
     * @param {FacilityType[]} facilityTypes
     * @param customer {Customer}
     */
    constructor(id, name, street, houseNumber, zipCode, city, facilityTypes,  customer) {
        this._id = id;
        this._name = name;
        this._street = street;
        this._houseNumber = houseNumber;
        this._zipCode = zipCode;
        this._city = city;
        this._facilityTypes = facilityTypes
        this._customer = customer;
    }

    /**
     * @param data
     * @param customer {Customer}
     * @return {Facility}
     */
    static fromResponse(data, customer) {
        return new Facility(
            data.id,
            data['Name'],
            data['Strasse'],
            data['Hausnummer'],
            data['Postleitzahl'],
            data['Ort'],
            data['einrichtungsarts'].map((item) => FacilityType.fromResponse(item)),
            customer
        );
    }

    /**
     * @return {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {string|null}
     */
    get name() {
        return this._name;
    }

    /**
     * @return {string}
     */
    get street() {
        return this._street;
    }

    /**
     * @return {string}
     */
    get houseNumber() {
        return this._houseNumber;
    }

    /**
     * @return {string}
     */
    get zipCode() {
        return this._zipCode;
    }

    /**
     * @return {string}
     */
    get city() {
        return this._city;
    }

    /**
     * @return {Customer}
     */
    get customer() {
        return this._customer;
    }

    /**
     * @return {string}
     */
    get address() {
        return `${this.street} ${this.houseNumber}, ${this.zipCode} ${this.city}`
    }


    get facilityTypes(): FacilityType[] {
        return this._facilityTypes;
    }

    set facilityTypes(value: Array) {
        this._facilityTypes = value;
    }

    /**
     * @return {string}
     */
    get nameWithCustomer() {
        var name =  this.name ? `${this.customer.name} (${this.name})` : this.customer.name;
        return name;
    }

    /**
     * @return {string}
     */
    get nameWithCustomerAndFacilityType() {
        var name =  this.nameWithCustomer;
        if(this.facilityTypes.length > 0) {
            name = name.concat(' - ', this.facilityTypes[0].name)
        }
        return name;
    }

    /**
     * @return {boolean}
     * @param searchText
     */
    matches(searchText) {
        if(searchText && searchText.length > 0) {
            let nameWithCustomer = this.nameWithCustomer;

            if(nameWithCustomer && nameWithCustomer.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }
            let address = this.address;
            if(address && address.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }
            return false;
        }
        return true;
    }

    /**
     *
     * @param {FacilityType}facilityType
     * @return {Facility}
     */
    cloneWithType(facilityType) {
        return new Facility(this.id, this.name, this.street, this.houseNumber, this.zipCode, this.city, (facilityType ? [facilityType] : []), this.customer);
    }
}
