import InspectionItem from "../dto/InspectionItem";
import {AsyncTask} from "./AsyncTask";

export const APPROVAL_TYPES = {
    APPROVE: 1,
    DENY: 2
}

export const TASK_ACTIONS = {
    CREATE: 1,
    UPDATE: 2,
    DELETE: 3
}

export const TASK_STATUS = {
    WAITING: 1,
    PROBLEM: 2,
    IN_PROGRESS: 3,
    PERSISTENT: 4
}

export default class InspectionItemTask extends AsyncTask {

    _originalInspectionItem = null;


    /**
     * @param {InspectionItem} inspectionItem
     * @param action
     * @param approval
     */
    constructor(inspectionItem, deleteAction = false) {
        super();
        const short = require('short-uuid');

        this._inspectionItem = inspectionItem.clone();
        this._id = short.generate();
        this._status = inspectionItem.id ?TASK_STATUS.PERSISTENT :  TASK_STATUS.WAITING ;
        if(!deleteAction) {
            this._action = inspectionItem.id ? TASK_ACTIONS.UPDATE : TASK_ACTIONS.CREATE;
        } else {
            this._action = TASK_ACTIONS.DELETE;
        }
    }



    /**
     * @returns {InspectionItem}
     */
    get inspectionItem() {
        return this._inspectionItem;
    }

    set inspectionItem(value: InspectionItem) {
        this._inspectionItem= value;
    }

    set id(id) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get action() {
        return this._action;
    }

    set action(value) {
        this._action = value;
    }


    get originalInspectionItem(): null {
        return this._originalInspectionItem;
    }

    set originalInspectionItem(value: null) {
        this._originalInspectionItem = value;
    }

    dutNumber() {
        return this.inspectionItem ? this.inspectionItem.dutNumber : null;
    }

    dutNumberFormatted() {
        return this.inspectionItem ? this.inspectionItem.dutNumberFormatted : null;
    }

    /**
     * @returns {InspectionItemTask}
     */
    clone() {
        return new InspectionItemTask(this.inspectionItem, this.action === TASK_ACTIONS.DELETE ? true : false);
    }

    serialize() {
        return {
            id: this.id,
            inspectionItem: this.inspectionItem.serialize(),
            status: this.status,
            action: this.action
        };
    }

    static fromResponse(data) {
        let iit =  new InspectionItemTask(
            InspectionItem.fromResponse(data['inspectionItem'])
        );
        iit.id = data.id ? data.id : null;
        iit.status = data.status ? data.status : TASK_STATUS.PROBLEM;
        iit.action = data.action ? data.action : TASK_ACTIONS.DELETE;
        return iit;
    }


}
