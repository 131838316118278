import {Grid, List} from "@material-ui/core";
import {useEffect, useState} from "react";
import ColoredList from "./ColoredList";
import {CREATIONS_STEPS} from "../../services/async-api/WizardTaskCollection";

export function DutActions(props) {

    let orders = ['zu entsorgen', 'zu reparieren'];
    let recommendations = ['bitte austauschen', 'bitte reparieren', 'zur Information'];
    let actions = ['bereits ausgetauscht', 'bereits entfernt'];
    let other = ['keine Auswahl'];

    const [selectedValue, setSelectedValue] = useState('')

    const [passed, setPassed] = useState(false);

    useEffect(() => {
        let t = props.taskCollection.firstTask();
        setPassed(t.inspectionItem.passed);

        if(props.taskCollection.firstTask().inspectionItem.action) {
            setSelectedValue(props.taskCollection.firstTask().inspectionItem.action);
        } else if(props.taskCollection.firstTask().inspectionItem.recommendation) {
            setSelectedValue(props.taskCollection.firstTask().inspectionItem.recommendation);
        }
    }, [props.taskCollection]);

    const chooseRecommendation = (item) => {
        chooseItem(item, false);
    }

    const chooseAction = (item) => {
        chooseItem(item, true);
    }

    const chooseNothing = () => {
        chooseItem(null, false);
    }

    const chooseItem =(item, isAction) => {
        let tc = props.taskCollection.clone();
        if(isAction) {
            tc.firstTask().inspectionItem.action = item;
            tc.firstTask().inspectionItem.recommendation = null
        } else {
            tc.firstTask().inspectionItem.recommendation = item;
            tc.firstTask().inspectionItem.action = null
        }
        tc.step = CREATIONS_STEPS.DUT_OVERVIEW;
        props.onTaskCollectionChanged(tc);

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h3 align={"center"}>Empfehlung</h3>
                    <List>
                        {!passed && (<ColoredList selectedValue={selectedValue} color={'#ff0000'} values={orders} callback={chooseRecommendation} ></ColoredList>)}
                        {passed && (<ColoredList selectedValue={selectedValue} color={'#EEE'} values={recommendations} callback={chooseRecommendation} ></ColoredList>)}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <h3  align={"center"}>Aktion</h3>
                    <List>
                        <ColoredList selectedValue={selectedValue} color={'#65cf0e'} values={actions} callback={chooseAction} ></ColoredList>
                    </List>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {/*<h3 align={"center"}>Sonstiges</h3>*/}
                    <List>
                        <ColoredList selectedValue={selectedValue} color={'#EEE'} values={other} callback={chooseNothing} ></ColoredList>
                    </List>
                </Grid>
            </Grid>
        </>
    );
}



