export default class FacilityType {

    /**
     * @param id {number}
     * @param name {string|null}
     */
    constructor(id, name) {
        this._id = id;
        this._name = name;
    }

    /**
     * @param data
     * @return {FacilityType}
     */
    static fromResponse(data) {
        return new FacilityType(
            data.id,
            data['Name'],
        );
    }


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string | null {
        return this._name;
    }

    set name(value: string | null) {
        this._name = value;
    }
}