import axios from "axios";
import * as localforage from 'localforage';
import DutCategory from './dto/DutCategory';
import MeasuringDevice from './dto/MeasuringDevice';
import Notice from './dto/Notice';
import {DUT_DEFAULT_MEDIA} from "./dto/Dut";

async function store(key, data, factoryMethod) {
    await localforage.setItem(key, JSON.stringify(data));
    return await load(key, factoryMethod);
}

async function load(key, factoryMethod) {
    const data = await localforage.getItem(key);

    if (typeof data !== 'string') {
        return [];
    }
    return JSON.parse(data).map((item) => factoryMethod(item));
}

export const DEFAULT_FLOORS = [
    {title: 'UG'},
    {title: 'EG'},
    {title: '1.OG'},
    {title: '2.OG'},
    {title: '3.OG'},
    {title: '4.OG'},
    {title: '5.OG'},
    {title: '6.OG'},
    {title: '7.OG'},
    {title: '8.OG'},
    {title: '9.OG'},
]

class BaseDataService {
    static KEY_CATEGORIES = 'categories';
    static KEY_NOTICES = 'notices';
    static KEY_MEASURING_DEVICES = 'measuringDevices';


    /**
     * @type {DutCategory[]}
     * @private
     */
    _dutCategories = [];
    /**
     * @type {Notice[]}
     * @private
     */
    _notices = [];
    /**
     * @type {MeasuringDevice[]}
     * @private
     */
    _measuringDevices = [];

    /**
     *
     * @type {MeasuringDevice}
     * @private
     */
    _selectedMeasuringDevice = null;


    // Non-Persistent
    _lastFloor = null;
    _lastRoom = null


    /**
     * @type {{}}
     * @private
     */
    _dutImageMap = {};

    _lastDutNumber = null;


    constructor() {
        load(BaseDataService.KEY_CATEGORIES, DutCategory.fromResponse).then((data) => this._dutCategories = data).then(() => {
            for (let i = 0; i < baseDataService._dutCategories.length; i++) {
                for (let j = 0; j < baseDataService._dutCategories[i].duts.length; j++) {
                    let dut = this.dutCategories[i].duts[j];
                    baseDataService.dutImageMap[dut.name] = dut.symbol;
                }
            }
        });
        load(BaseDataService.KEY_NOTICES, Notice.fromResponse).then((data) => this._notices = data);
        load(BaseDataService.KEY_MEASURING_DEVICES, MeasuringDevice.fromResponse).then((data) => this._measuringDevices = data).then(() => {

        });
    }

    findDutImageByName(dutName) {
        console.log(this.dutImageMap);
        let image = this.dutImageMap[dutName];
        if(!image) {
            image = DUT_DEFAULT_MEDIA;
        }
        return image;
    }

    async loadDutCategories() {
        let {data} = await axios.get('/dut-categories?_limit=-1');
        baseDataService._dutCategories = await store(BaseDataService.KEY_CATEGORIES, data, DutCategory.fromResponse);
    }

    async loadNotices() {
        let {data} = await axios.get('/notices?_limit=-1');
        baseDataService._notices = await store(BaseDataService.KEY_NOTICES, data, Notice.fromResponse);
    }

    async loadMeasuringDevices() {
        let {data} = await axios.get('/measuring-devices?_limit=-1');
        baseDataService._measuringDevices = await store(BaseDataService.KEY_MEASURING_DEVICES, data, MeasuringDevice.fromResponse);
    }

    async loadBaseData() {
        let {data} = await axios.get('/baseData');
        baseDataService._dutCategories = await store(BaseDataService.KEY_CATEGORIES, data.categories, DutCategory.fromResponse);
        for (let i = 0; i < baseDataService._dutCategories.length; i++) {
            for (let j = 0; j < baseDataService._dutCategories[i].duts.length; j++) {
                let dut = this.dutCategories[i].duts[j];
                baseDataService.dutImageMap[dut.name] = dut.symbol;
            }
        }
        baseDataService._notices = await store(BaseDataService.KEY_NOTICES, data.notices, Notice.fromResponse);
        baseDataService._measuringDevices = await store(BaseDataService.KEY_MEASURING_DEVICES, data.measuringDevices, MeasuringDevice.fromResponse);
    }
    async  clearLocalStorage() {
        await localforage.removeItem(BaseDataService.KEY_CATEGORIES);
        await localforage.removeItem(BaseDataService.KEY_NOTICES);
        await localforage.removeItem(BaseDataService.KEY_MEASURING_DEVICES);
    }

    /**
     * @return DutCategory[]
     */
    get dutCategories() {
        return this._dutCategories;
    }

    /**
     * @returns {Notice[]}
     */
    get notices() {
        return this._notices;
    }

    /**
     * @returns {MeasuringDevice[]}
     */
    get measuringDevices() {
        return this._measuringDevices;
    }


    get selectedMeasuringDevice(): null {
        return this._selectedMeasuringDevice;
    }

    set selectedMeasuringDevice(value: null) {
        this._selectedMeasuringDevice = value;
    }

    get dutImageMap(): {} {
        return this._dutImageMap;
    }

    get lastDutNumber(): null {
        return this._lastDutNumber;
    }

    set lastDutNumber(value: null) {
        this._lastDutNumber = value;
    }

    get lastFloor(): null {
        return this._lastFloor;
    }

    set lastFloor(value: null) {
        this._lastFloor = value;
    }

    get lastRoom(): null {
        return this._lastRoom;
    }

    set lastRoom(value: null) {
        this._lastRoom = value;
    }
}

const baseDataService = new BaseDataService();
export default baseDataService;