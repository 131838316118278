export default class User {
    /**
     * @param id {number}
     * @param firstName {string}
     * @param lastName {string}
     * @param isAdmin {boolean}
     */
    constructor(id, firstName, lastName, isAdmin) {
        this._id = id;
        this._firstName = firstName;
        this._lastName = lastName;
        this._initials = firstName.substring(0, 1) + lastName.substring(0, 1);
        this._isAdmin = isAdmin;
    }

    static fromResponse(data) {
        return new User(data.id, data['Vorname'], data['Nachname'], data['isAdmin']);
    }

    serialize() {
        return {
            id: this.id,
            Vorname: this.firstName,
            Nachname: this.lastName,
            isAdmin: this.isAdmin
        }
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * @returns {string}
     */
    get initials() {
        return this._initials;
    }

    /**
     * @return {string}
     */
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    get isAdmin(): boolean {
        return this._isAdmin;
    }
}
