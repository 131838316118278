import React, {useEffect, useState} from "react";
import baseDataService from "../../services/BaseDataService";
import {
    AppBar, Button,
    Card,
    CardActionArea,
    CardContent,
    Grid, Paper,
    Tab,
    Tabs, TextField,
    Typography
} from "@material-ui/core";
import localforage from "localforage";
import PropTypes from 'prop-types';
import {CREATIONS_STEPS, WizardTaskCollection} from "../../services/async-api/WizardTaskCollection";
import Dut from "../../services/dto/Dut";
import Autocomplete from "@material-ui/lab/Autocomplete";
import syncService from "../../services/async-api/SyncService";

const CustomPaper = (props) => {
    return <Paper style={{backgroundColor: "#0f385f", color:'white'}} elevation={8} {...props} />;
};

function DutChooser(props) {

    const [dutCategories, setDutCategories] = useState([]);
    const [allDuts, setAllDuts] = useState([]);
    const [currentDuts, setCurrentDuts] = useState([]);
    const [currentDutCategory, setCurrentDutCategory] = useState(0);
    const [customDutName, setCustomDutName] = useState('');
    const inputRef = React.useRef();
    const [customDutNames, setCustomDutNames] = useState([]);

    useEffect(() => {
        setDutCategories(baseDataService.dutCategories);
        let allDuts = []
        let customDuts = new Set();
        syncService.currentInspection.items.forEach(value => {
            customDuts.add(value.dut);
        })
        baseDataService.dutCategories.forEach((category, index) => {
            category.duts.forEach((dut) => {
                customDuts.delete(dut.name);
                let cDut = allDuts.find((cDut) => {
                    return cDut.id === dut.id;
                });
                if (!cDut) {
                    dut['categories'] = [category.id]
                    allDuts.push(dut);
                } else {
                    cDut.categories.push(category.id);
                }
            });
        });
        allDuts = allDuts.sort((a, b) => {
            return a.position - b.position;
        });
        setAllDuts(allDuts);
        setCustomDutNames([...customDuts].sort((a, b) => a.localeCompare(b)));
        localforage.getItem('dutchooser.selectedTab').then((value) => {
            if(value) {
                setCurrentDutCategory(value);
            }
        });
        localforage.getItem('dutchooser.customDutName').then((value) => {
            if(value) {
                setCustomDutName(value);
            }
        });
    }, [])

    useEffect(() => {
        localforage.setItem('dutchooser.customDutName', customDutName);
    }, [customDutName])

    useEffect(() => {
        if(currentDutCategory !== dutCategories.length) {
            let tempCurrentDuts = [];
            for (let i = 0; i < allDuts.length; i++) {
                if(allDuts[i].categories.includes(dutCategories[currentDutCategory].id)) {
                    tempCurrentDuts.push(allDuts[i]);
                }
            }
            setCurrentDuts(tempCurrentDuts);
        }
        localforage.setItem('dutchooser.selectedTab', currentDutCategory);
    }, [allDuts, currentDutCategory, dutCategories]);

    const categories = dutCategories.map((category, index) => {
        return (
            <Tab key={category.id} onClick={() => {
                setCurrentDutCategory(index);
            }} label={category.name}></Tab>
        );
    });

    const dutSelected = (dut) => {
        let taskCollection = props.taskCollection.clone();
        taskCollection.updateDut(dut);
        taskCollection.step = CREATIONS_STEPS.DUT_OPTIONS;
        props.onTaskCollectionChanged(taskCollection);
    }

    const changeCustomDut = (evt, value) => {
        // alert(value);
        console.log(value);
        setCustomDutName(value)
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12} >
                    <AppBar position={"relative"}>
                        <Tabs value={currentDutCategory} variant={"scrollable"} scrollButtons={"auto"} style={{width: '100%'}} >
                            {categories}
                            <Tab key={dutCategories.length} label={"Sonstige"} onClick={() => {
                                setCurrentDutCategory(dutCategories.length);
                            }}>
                            </Tab>
                        </Tabs>
                    </AppBar>
                    <Grid container spacing={1} style={{paddingTop: '20px'}}>
                        {
                            currentDutCategory < dutCategories.length && allDuts.map((dut, index) => {
                                let cDut = currentDuts[index];
                                if(cDut) {
                                    return (<DutTile dutSelected={dutSelected} key={index} dut={cDut}></DutTile>)
                                }
                                return null;
                            })
                        }
                        {
                            currentDutCategory === dutCategories.length && (
                                <>
                                    <Grid container direction={"row"} justifyContent={"center"} item style={{paddingTop: '30px'}} spacing={2}>
                                        <Grid item xs={8} md={6}>
                                            {/*<TextField onChange={changeCustomDutName} value={customDutName} fullWidth label={"Sonstiges Betriebsmittel"} variant={"standard"}></TextField>*/}
                                            <Autocomplete  value={customDutName} PaperComponent={CustomPaper} onInputChange={(evt, value) => {
                                                changeCustomDut(evt, value);
                                            }}  freeSolo renderInput={(params) => (
                                                <TextField {...params} inputRef={inputRef} label={'Sonstiges Betriebsmittel'} margin="normal" variant="outlined" fullWidth />
                                            )} options={customDutNames} />
                                        </Grid>
                                        <Grid  item xs={4} md={6} container direction={"column"} justifyContent={"center"}>
                                            <Button disabled={customDutName.length === 0} variant={"contained"} color={"primary"} onClick={() => {
                                                let dut = new Dut(null, customDutName, null, null, 0);
                                                dutSelected(dut);
                                            }}>Übernehmen</Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}
DutChooser.propTypes = {
    taskCollection: PropTypes.instanceOf(WizardTaskCollection).isRequired,
    onTaskCollectionChanged: PropTypes.func.isRequired
}
export default DutChooser;


function DutTile(props) {

    let dut = props.dut;

    return (
        <Grid item xs={2} key={dut?.id}>
            <Card elevation={0} style={{border: '1px solid lightgrey'}}>
                <CardActionArea onClick={() => {
                    props.dutSelected(dut);
                }}>
                    <div style={{width: '100%', height:'100px'}}>
                            <img alt={'Betriebsmittelsymbol'} src={dut.symbol.image()} style={{
                                position: 'absolute',
                                margin: 'auto',
                                objectFit: 'contain',
                                height:'100px',
                                width:'100%'
                            }} />

                    </div>
                    <CardContent style={{padding: 1, height:40}}>
                        <div style={{position: 'relative', width: '100%', height: '100%', textAlign: 'center', wordWrap: 'break-word'}}>
                            <Typography gutterBottom variant="body2"
                                        style={{
                                            height: '100%', wordWrap: 'break-word', display: "flex",
                                            width: '100%',
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            fontWeight: 'bold',
                                            position: 'absolute',
                                        }}>
                                {dut.name}
                            </Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

