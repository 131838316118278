import InspectionItemTask from "./InspectionItemTask";

export const CREATIONS_STEPS = {
    DUT_CHOOSE: 1,
    DUT_OPTIONS: 2,
    DUT_NOTICES: 3,
    DUT_ACTIONS: 4,
    DUT_OVERVIEW: 5,
    DUT_FINISHED: 99,
};

export const ASYNC_ACTIONS = {
    ASYNC_ACTION_CREATE: 1,
    ASYNC_ACTION_UPDATE: 2,
    ASYNC_ACTION_DELETE: 3
}

export class WizardTaskCollection {

    constructor(tasks : InspectionItemTask[], step) {
        this._tasks = tasks;
        this._step = step;
    }

    get step() {
        return this._step;
    }

    get tasks(): InspectionItemTask[] {
        return this._tasks;
    }

    set tasks(value: Array) {
        this._tasks = value;
    }

    set step(value) {
        this._step = value;
    }

    taskCount() {
        if(!this.tasks) {
            return 0;
        } else {
            return this.tasks.length;
        }
    }

    /**
     * @returns {null|InspectionItem}
     */
    firstTask() {
        if(this.taskCount() > 0) {
            return this.tasks[0];
        }
        return null;
    }

    lastTask() {
        if(this.taskCount() > 0) {
            return this.tasks[this.tasks.length-1];
        }
        return null;
    }

    /**
     * @param {Dut} dut
     */
    updateDut(dut) {
        this.tasks.forEach((task) => {
            task.inspectionItem.dut = dut.name;
            if(dut.defaultProtectionClass) {
                task.inspectionItem.protectionClass = dut.defaultProtectionClass;
            }

        });
    }

    /**
     * @returns {WizardTaskCollection}
     */
    stepBack() {
        let taskCollection = this.clone();
        if(taskCollection.step > 1) {
            taskCollection.step--;
        }
        return taskCollection;
    }

    /**
     * @returns {WizardTaskCollection}
     */
    clone() {
        return new WizardTaskCollection(this.tasks, this.step)
    }
}