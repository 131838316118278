import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, IconButton,
    List,
    ListItem,
    ListItemText, makeStyles,
} from "@material-ui/core";
import {useEffect, useState} from "react";
import syncService from "./SyncService";
import PropTypes from "prop-types";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {TASK_STATUS} from "./InspectionItemTask";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import {DeleteOutline} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    listItem: {
        borderBottom: '1px solid lightgrey',
        marginBottom: '2px'
    },

}));

export default function TaskList(props) {


    const [tasks, setTasks] = useState([]);
    const [, setRemainingCount] = useState(0);
    const [, setTaskInProgress] = useState(null);
    const [errorCount, setErrorCount] = useState(0);


    useEffect(() => {
        if(props.open) {
            console.log('update in list');
            setTasks(syncService.currentTaskQueue);
            let data = syncService.generateStatusUpdate();
            onStatusUpdate(data)
        }
    }, [props.open]);

    const onStatusUpdate = (data) =>  {
        setRemainingCount(data.outstanding)
        setTaskInProgress(data.currentTask);
        setErrorCount(data.tasksWithError.length);
    }

    useEffect(() => {
        let mySubscriber = function (msg, data) {
            console.log('update in list');
            setTasks(syncService.currentTaskQueue);
            onStatusUpdate(data);
            //forceUpdate();
        };
        let token = syncService.subscribeForOnlineStatusUpdates(mySubscriber)
        return () => {
            syncService.unsubscribeFromOnlineStatusUpdates(token);
        }
    })

    const handleRetryTasks = () => {
       syncService.retryErrorTasks();
    }


    const classes = useStyles();
    return (
        <Dialog onClose={props.onClose} maxWidth={"xl"} open={props.open}>
            <DialogContent>
                <List>
                    {tasks.slice(0,5).map((task, index) => {
                        return (
                            <ListItem key={index} className={`${classes.listItem}`} dense>
                                <ListItemText
                                    primary={task.inspectionItem.toString()}
                                    secondary={`Etage: ${task.inspectionItem.floor} Raum: ${task.inspectionItem.room}`}>
                                </ListItemText>

                                <TaskStatus status={task.status} size={30} />
                                <IconButton onClick={() => {
                                    syncService.removeTasksFromQueue(task);
                                }}><DeleteOutline></DeleteOutline></IconButton>
                            </ListItem>
                        )
                    })}
                    {tasks.length > 5 && (
                        <ListItem>
                            <ListItemText> + <b>{tasks.length-5}</b> weitere Prüflinge...</ListItemText>
                        </ListItem>
                    )}
                    {tasks.length === 0 && (
                        <div>
                            <p align={"center"}><InsertEmoticonIcon color={"primary"} style={{ fontSize: 80 }} /></p>
                            <p>Es gibt aktuell keine Synchronisationsprobleme...</p>
                        </div>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button disabled={errorCount === 0} fullWidth color={"primary"} variant={"contained"} onClick={handleRetryTasks}>Synchronisation wiederholen</Button>
            </DialogActions>
        </Dialog>
    );
}

export function TaskStatus(props) {
    if(props.status === TASK_STATUS.PROBLEM) {
        return (
            <ErrorOutlineIcon style={{ fontSize: props.size }}  color={"secondary"} />
        )
    }
    // if(props.status === TASK_STATUS.WAITING) {
    //     return (
    //         <HourglassFull style={{ fontSize: props.size }} color={"primary"}></HourglassFull>
    //     )
    // }
    if(props.status === TASK_STATUS.IN_PROGRESS || props.status === TASK_STATUS.WAITING) {
        return (
            <CircularProgress color={"secondary"} size={props.size} />
        )
    }
    return (
        <>  </>
    )
}

TaskList.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    inspectionId: PropTypes.number
}