import {
    Avatar,
    Button,
    Card,
    CardHeader, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton, Link,
    List,
    ListItem, ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import {KeyboardDatePicker} from '@material-ui/pickers';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import {authService} from '../services/AuthService';
import {customerService} from '../services/CustomerService';
import Inspection from "../services/dto/Inspection";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    header: {
        padding: 5,
        marginTop: 10,
    },
    datePicker: {
        paddingTop: 10,
        paddingBottom: 10
    },
    invalidCard: {
        borderColor: "red",
        borderWidth: 2,
        borderStyle: "solid",
        color: "red"
    },
    invalidIcon: {
        color: "red",
    }
}));

function NewInspection(props) {
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [selectedDate, setSelectedDate] = useState(DateTime.now());
    const [isFacilityChooserShown, setFacilityChooserShown] = useState(false);

    const didSelectFacility = (facility, begin) => {
        setSelectedFacility(facility);
        setFacilityChooserShown(false);
        if(!begin) {
            begin = selectedDate
        }
        if(facility) {
            let newInspection = new Inspection(null, begin, null, facility.customer,facility);
            props.onInspectionCreated(newInspection);
        } else {
            props.onInspectionCreated(null);
        }

    }


    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.header}>Einrichtung</Typography>
            <Card className={!selectedFacility ? classes.invalidCard : null}>
                <ListItem>
                    <ListItemText primary={selectedFacility ? selectedFacility.nameWithCustomerAndFacilityType : 'Bitte Einrichtung auswählen...'}
                                  secondary={selectedFacility ? selectedFacility.address : null} >

                    </ListItemText>
                    {/*<ListItemSecondaryAction>*/}
                        <IconButton className={!selectedFacility ? classes.invalidIcon : null} aria-label="add new" onClick={() => { setFacilityChooserShown(true)}}>
                            <EditOutlinedIcon />
                        </IconButton>
                    {/*</ListItemSecondaryAction>*/}
                </ListItem>
            </Card>
            <FacilityDialog open={isFacilityChooserShown} onFacilitySelected={didSelectFacility}/>
            <Typography className={classes.header}>Startdatum</Typography>
            <Card>
                <ListItem>
                    <KeyboardDatePicker

                        className={classes.datePicker}
                        fullWidth
                        format={'dd.MM.yyyy'}
                        value={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date)
                            if(selectedFacility) {
                                didSelectFacility(selectedFacility, date);
                            }
                        }}
                    />
                </ListItem>
            </Card>


            <Typography className={classes.header}>Prüfleiter</Typography>
            <Card >
                <CardHeader
                    avatar={
                        <Avatar>{authService.user.initials}</Avatar>
                    }
                    title={authService.user.fullName}
                />
            </Card>
        </div>
    );
}

function FacilityDialog(props) {
    const [facilities, setFacilities] = useState([]);
    // const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [currentFacility, setCurrentFacility] = useState(null)
    const [facilityTypeDialogOpen, setFacilityTypeDialogOpen] = useState(false);
    const [minSearchTermLength, setMinSearchTermLength] = useState(3);
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        // setLoading(true);
        customerService.loadCustomers().then((customers) => {
            if (!isSubscribed) {
                return;
            }
            let facilities = customers.reduce((array, customer) => {
                array.push(...customer.facilities);
                return array;
            }, []);
            setFacilities(facilities);
            // setLoading(false);
        });
        return () => isSubscribed = false;
    }, []);


    const changeSearchText = (evt) => {
        setSearchText(evt.target.value);
    }

    const selectFacility = (facility) => {
        setFacilityTypeDialogOpen(false);
        props.onFacilitySelected(facility);
    }

    const handleClose = () => {
        props.onFacilitySelected(null);
    }


    const facilityListItems = facilities.filter(value => {
        return value.matches(searchText);
    }).map((facility) => {
            let hasFacilityTypes = facility.facilityTypes.length > 0;
            return (
                <Fragment key={`fragment-${facility.id}`} >
                    <ListItem key={`key-${facility.id}`} button onClick={() => {
                        if(facility.facilityTypes.length > 0) {
                            setFacilityTypeDialogOpen(true)
                            setCurrentFacility(facility);
                        } else {
                            selectFacility(facility)
                        }
                    }}>
                        <ListItemText primary={facility?.nameWithCustomer} secondary={facility.address}/>
                        <ListItemSecondaryAction>
                            <Chip color={hasFacilityTypes ? "primary" : ""} label={facility.facilityTypes.length + 1} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>
                </Fragment>
            )
    });

    const useStyles = makeStyles({
        dialogPaper: {
            minHeight: '90%',
            minWidth: '50%'
        },
    });
    const classes = useStyles();

    useEffect(() => {
        if(props.open) {
            setTimeout(() => {
                setFocus(true);
            }, 1000);
        } else {
            setFocus(false);
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            // PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            disableRestoreFocus={true}
            PaperProps={{
                style: {
                    maxHeight: '90%',
                }
            }}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="choose-facility" >
                Liste aller Einrichtungen
                <Grid>
                    <Grid item>
                        <TextField autoFocus fullWidth value={searchText} onChange={changeSearchText} placeholder={'Suche nach Einrichtungen'} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {searchText.length >= minSearchTermLength && facilityListItems.length > 0 && (
                    <List component="nav">
                        { facilityListItems }
                    </List>
                )}
                {searchText.length < minSearchTermLength && (
                    <>
                        <Alert severity={"info"}>Bitte Suchbegriff mit mindestens <b>3 Zeichen</b> eingeben, oder auf den Button "Alle Anzeigen" klicken.</Alert>
                        <Button fullWidth variant={"outlined"} color={'primary'} style={{top: '20px'}} onClick={() => {
                            setMinSearchTermLength(0)
                        }}>Alle anzeigen</Button>
                    </>

                )}
                {facilityListItems.length === 0 && (
                    <Alert severity={"error"}>Keine Kunden für den Suchbegriff "<b>{searchText}</b>" gefunden</Alert>
                )}

                <FacilityTypeDialog
                    facilityTypes={currentFacility?.facilityTypes}
                    facility={currentFacility}
                    open={facilityTypeDialogOpen}
                    onFacilityTypeSelected={(facilityType) => {
                        selectFacility(currentFacility.cloneWithType(facilityType))
                    }}>
                </FacilityTypeDialog>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function FacilityTypeDialog(props) {

    const facilityListItems = props.facilityTypes?.map((facilityType) => {
        return (
            <>
                <ListItem button onClick={() => {
                    props.onFacilityTypeSelected(facilityType);
                }}>
                    <ListItemText primary={facilityType.name} />
                </ListItem>
                <Divider/>
            </>
        );
    });

    return (
        <Dialog open={props.open}>
            <DialogTitle style={{textAlign: 'center'}}>Einrichtungsart auswählen für <br /><b>'{props.facility?.nameWithCustomer}'</b></DialogTitle>
            <DialogContent>
                <List component="nav">
                    <ListItem button onClick={() => {
                        props.onFacilityTypeSelected(null);
                    }}>
                        <ListItemText primary={'keine Einrichtungsart'} />
                    </ListItem>
                    <Divider/>
                    {facilityListItems}
                </List>
            </DialogContent>
        </Dialog>
    )
}

FacilityDialog.prototype = {
    onFacilitySelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}


NewInspection.propTypes = {
    onInspectionCreated: PropTypes.func.isRequired,
    onAborted: PropTypes.func.isRequired
}
export default NewInspection;