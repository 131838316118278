import localforage from "localforage";

const SETTINGS_KEYS = {
    SHOW_SERVER_RESPONSE: 'settings.showServerResponse',
}


class SettingsService {

    _showServerResponse = false

    constructor() {
        localforage.getItem(SETTINGS_KEYS.SHOW_SERVER_RESPONSE).then((value) => {
            if(value === null) {
                value = false;
            }
            this.showServerResponse = value;
        })
    }


    get showServerResponse(): boolean {
        return this._showServerResponse;
    }

    set showServerResponse(value: boolean) {
        this._showServerResponse = value;
        localforage.setItem(SETTINGS_KEYS.SHOW_SERVER_RESPONSE, value);
    }
}
export const settingsService = new SettingsService();
