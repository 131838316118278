import {
    Button, CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper, TextField
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {OverViewItem} from "../DutWizard/DutOverview";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Inspection from "../../services/dto/Inspection";
import validator from "validator";
import {inspectionService} from "../../services/InspectionService";
import syncService from "../../services/async-api/SyncService";

export default function ErrorReportDialog(props) {

    const [isSaving, setIsSaving] = useState(false);
    const [didUpdateInspections, setDidUpdateInspections] = useState(false);
    const [mailRecipient, setMailRecipient] = useState('');
    const [mailInvalid, setMailInvalid] = useState(false);

    const handleClose = () => {
        props.handleClose();
    }

    const validateMail = () => {
        if(mailRecipient === '') {
            return true;
        }
        return validator.isEmail(mailRecipient);
    }

    const handleSendReport = () => {
        if(validateMail() && mailRecipient.length > 0) {
            setIsSaving(true);
            inspectionService.sendErrorReport(props.inspection, mailRecipient).then(() => {
                setIsSaving(false);
                props.handleClose();
                setMailInvalid('');
            })
        } else {
            alert('Bitte gültige Mailadresse eingeben!')
        }

    }

    useEffect(() => {
        if(props.open === true) {

            setDidUpdateInspections(false);
            inspectionService.loadInspection(syncService.currentInspection.id).then((inspection) => {
                syncService.changeInspection(inspection);
                setDidUpdateInspections(true);
            });
        } else {
            setDidUpdateInspections(false)
        }
    }, [props.open, props.inspection.items])

    return (
        <Dialog onClose={handleClose} maxWidth={"xs"} fullWidth={true} open={props.open}>
            <DialogTitle>Fehlerbericht erstellen
                <Button style={{position: "absolute", top: '5px', right: '5px'}} onClick={handleClose} color="primary">
                    <Close style={{fontSize: '35px'}}/>
                </Button>
            </DialogTitle>
            <DialogContent>
                {didUpdateInspections && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Paper style={{padding: '10px'}}>
                                <OverViewItem title={'Versand an'} value={
                                    <TextField value={mailRecipient} onChange={(evt) => {
                                        setMailRecipient(evt.target.value);
                                        setMailInvalid(false)
                                    }} error={!validateMail()}  placeholder={"E-Mail-Adresse"}></TextField>
                                } nodivider></OverViewItem>
                                {mailInvalid && (
                                    <Alert style={{marginTop: 8}} severity="error">Die E-Mail-Adresse ist ungültig</Alert>
                                )}
                            </Paper>

                        </Grid>
                    </Grid>
                )}
                {!didUpdateInspections && (
                    <Grid container style={{paddingTop: 20, paddingBottom: 20}} >
                        <Grid item xs={12} justify={"center"} direction={"row"} container>
                            <CircularProgress size={40} style={{marginBottom: 20}}></CircularProgress>
                        </Grid>
                        <Grid item xs={12} justify={"center"} direction={"row"} container>
                            Aktualisiere Prüflinge
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={!didUpdateInspections ||  isSaving} fullWidth color={"primary"} variant={"contained"} onClick={handleSendReport}>
                    {isSaving ? <CircularProgress/> : 'Fehlerbericht versenden'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ErrorReportDialog.propTypes = {
    inspection: PropTypes.instanceOf(Inspection).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCompleted: PropTypes.func.isRequired
}