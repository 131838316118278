import Media from './Media';

export const DUT_DEFAULT_MEDIA = new Media(-1, '/img/dut_default.svg');
export default class Dut {
    /**
     * @param id {number}
     * @param name {string}
     * @param defaultProtectionClass {string}
     * @param symbol {Media|null}
     * @param position {number}
     */
    constructor(id, name, defaultProtectionClass, symbol, position) {
        this._id = id;
        this._name = name;
        this._defaultProtectionClass = defaultProtectionClass;
        this._symbol = symbol;
        this._position = position;
    }


    static fromResponse(data) {
        return new Dut(
            data.id,
            data['Name'],
            data['StandardSchutzklasse'],
            data['Symbol'] ? Media.fromResponse(data['Symbol']) : DUT_DEFAULT_MEDIA,
            data['Sortierung']
        );
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @returns {string}
     */
    get defaultProtectionClass() {
        return this._defaultProtectionClass;
    }

    /**
     * @returns {Media|null}
     */
    get symbol() {
        return this._symbol;
    }

    /**
     * @returns {number}
     */
    get position() {
        return this._position;
    }
}