import {
    AppBar, Backdrop, CircularProgress,
    IconButton,
    makeStyles, Menu, MenuItem, Snackbar,
    Toolbar,
    Typography
} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {AssignmentTurnedIn, ExitToApp, Power, PowerOff, Refresh, Search, Settings} from '@material-ui/icons';
import React, {Fragment, useEffect, useState} from "react";
import {useBeforeunload} from 'react-beforeunload';
import './App.css';
import Loader from "./components/Loader";
import Login from "./components/Login";
import OpenInspection from './components/OpenInspection';
import {authService} from "./services/AuthService";
import InspectionList from "./components/Inspection/InspectionList";
import SyncWidget from "./services/async-api/SyncWidget";
import TaskList from "./services/async-api/TaskList";
import syncService from "./services/async-api/SyncService";
import baseDataService from "./services/BaseDataService";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import SettingsDialog from "./components/Settings/SettingsDialog";
import {inspectionService} from "./services/InspectionService";
import {Alert} from "@material-ui/lab";
import SyncService from "./services/async-api/SyncService";
import BaseDataService from "./services/BaseDataService";

const activities = {
    login: 1,
    loading: 2,
    openInspection: 3,
    inspection: 4
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        flexGrow: 1,
    }
}));

function App() {

    const [activity, setActivity] = useState(authService.authToken ? activities.loading : activities.login);
    const [inspection, setInspection] = useState(null);
    const [taskListShown, setTaskListShown] = useState(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [searchDialogOpen, setSearchDialogOpen] = useState(false);
    const [completeInspectionDialogOpen, setCompleteInspectionDialogOpen] = useState(false);
    const [showRefreshSuccess, setShowRefreshSuccess] = useState(false);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const [errorList, setErrorList] = useState(false);

    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#0F385F'
            },
            secondary: {
                main: '#D12023'
            }
        },
    });

    const hideRefreshSuccess = () => {
        setShowRefreshSuccess(false);
    }

    const getView = (activity) => {
        switch (activity) {
            case activities.login:
                return {
                    component: <Login onLogin={login}/>
                };
            case activities.loading:
                return {
                    title: 'Initialisierung',
                    component: <Loader onLoaded={baseDataLoaded}/>
                };
            case activities.openInspection:
                return {
                    title: 'Prüfung wählen oder starten',
                    component: <OpenInspection onInspectionSelected={onInspectionSelected}/>,
                    auxComponent: <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                    <MoreVertIcon style={{color: "white"}} />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        popupState.close();
                                        setSettingsDialogOpen(true);
                                    }}><Settings style={{paddingRight: 10}}/> Einstellungen</MenuItem>
                                    <MenuItem onClick={() => {
                                        popupState.close();
                                        logout()
                                    }}><ExitToApp style={{paddingRight: 10}}/> Logout</MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                    </PopupState>
                };
            case activities.inspection:
                return {
                    title: inspection.facility.nameWithCustomerAndFacilityType,
                    backBtnIcon: <ExitToApp/>,
                    component: <InspectionList
                        inspection={inspection}
                        showSearchDialog={searchDialogOpen}
                        onCloseSearchDialog={() => {setSearchDialogOpen(false)}}
                        showCompleteInspectionDialog={completeInspectionDialogOpen}
                        onCloseCompleteInspectionDialog={() => {setCompleteInspectionDialogOpen(false)}}
                        onCompletedInspection={onInspectionCompleted}
                        showErrorList={errorList}
                        onSwitchInspection={(inspection) => {

                            if(syncService.generateStatusUpdate().outstanding > 0) {
                                alert('Der Wechsel der Einrichtung kann nur durchgeführt werden, wenn alle Prüflinge synchronisiert sind.');
                            } else {
                                setInspection(inspection)
                            }
                        }}
                    />,
                    auxComponent: <div style={{minWidth:'10px', alignContent: 'end', display:'flex', justifyContent: 'end'}}>
                        <SyncWidget showTaskList={() => {
                            setTaskListShown(true);
                        }} />
                        <PopupState variant="popover" popupId="inspection-menu">
                            {(popupState) => (
                                <Fragment>
                                    <IconButton {...bindTrigger(popupState)} style={{marginLeft: '5px'}}>
                                        <MoreVertIcon style={{color: "white"}} />
                                    </IconButton>
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem onClick={() => {
                                            popupState.close();
                                            setSearchDialogOpen(true);
                                        }}><Search style={{paddingRight: 10}}/> Prüfling suchen</MenuItem>
                                        {inspectionService.canCompleteInspection(inspection) && <MenuItem onClick={() => {
                                            popupState.close();
                                            if(syncService.currentTaskCount() > 0) {
                                                alert(`Die Prüfung kann erst abgeschlossen werden, wenn alle Prüfeinträge synchronisiert wurden.`);
                                            } else {
                                                setCompleteInspectionDialogOpen(true);
                                            }

                                        }}><AssignmentTurnedIn style={{paddingRight: 10}}/> Prüfung abschließen</MenuItem>}
                                        <MenuItem onClick={() => {
                                            popupState.close();
                                            inspectionService.loadInspection(syncService.currentInspection.id).then((inspection) => {
                                                syncService.changeInspection(inspection).then(() => {
                                                    setShowRefreshSuccess(true);
                                                });
                                            });

                                        }}><Refresh style={{paddingRight: 10}}/> Prüflinge aktualisieren</MenuItem>
                                        <MenuItem onClick={() => {
                                            setErrorList(!errorList);
                                            popupState.close();
                                        }}>
                                            {!errorList && (
                                                <>
                                                    <PowerOff style={{paddingRight: 10}}/>
                                                    Fehlerliste
                                                </>

                                            )}
                                            {errorList && (
                                                <>
                                                    <Power style={{paddingRight: 10}}/>
                                                    Standardliste
                                                </>

                                            )}
                                            </MenuItem>
                                    </Menu>
                                </Fragment>
                            )}
                        </PopupState>
                    </div>
                };
            default:
                return {
                    title: '',
                    component: ''
                }
        }
    }

    //TODO: Prüfen ob das noch benötigt wird
    useBeforeunload((event) => {
        if (inspection && activity !== activities.chooseMeasure) {
            event.preventDefault();
        }
    });

    const login = () => {
        setActivity(activities.loading);
    }

    const baseDataLoaded = () => {
        setActivity(activities.openInspection);
    }

    useEffect(() => {
        if(inspection) {
            // syncService.changeInspection(inspection);
            setShowLoadingIndicator(true)
            inspectionService.loadInspection(inspection.id).then((inspection) => {
                syncService.changeInspection(inspection).then(() => {
                    setShowLoadingIndicator(false)
                });
            });
        }
    },[inspection])

    const onInspectionSelected = (inspection, measuringDevice) => {
        setInspection(inspection);
        baseDataService.selectedMeasuringDevice = measuringDevice;
        //syncService.changeInspection(inspection);
        setActivity(activities.inspection);
    }

    const onInspectionCompleted = () => {
        setCompleteInspectionDialogOpen(false);
        navigateBack();
    }

    const navigateBack = () => {
        switch (activity) {
            case activities.inspection:
                //const confirm = useConfirm();
                if(syncService.generateStatusUpdate().outstanding > 0) {
                    let conf = window.confirm('Es sind noch nicht alle Daten synchronisiert. Wollen Sie die Synchronisierung jetzt durchführen?');
                    if(conf) {
                        setTaskListShown(true);
                        return;
                    }
                }
                setActivity(activities.openInspection);
                setInspection(null);
                setErrorList(false);
                SyncService.changeInspection(null);
                BaseDataService.lastDutNumber = null;
                BaseDataService.lastRoom = null;
                BaseDataService.lastFloor = null;
                break;

            default:
                setActivity(activities.login);
                break;
        }
    }

    const logout = () => {
        authService.logout();
        setActivity(activities.login);
    }

    const view = getView(activity);


    let toolBarStyle = {
        paddingRight: 5
    }
    if(errorList) {
        toolBarStyle['backgroundColor'] = '#d21e23';
    }


    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                {view.title && <AppBar position="sticky">
                    <Toolbar style={toolBarStyle}>
                        {view.backBtnIcon && <IconButton edge={'start'} color="inherit" onClick={navigateBack}>
                            {view.backBtnIcon}
                        </IconButton>}
                        <Typography className={classes.title} onClick={() => {

                        }
                        }>{view.title}</Typography>
                        <div style={{}}>
                            {view.auxComponent && (
                                view.auxComponent
                            )}
                        </div>
                    </Toolbar>
                </AppBar>}
                <div className={classes.content}>{view.component}</div>
                <TaskList open={taskListShown} onClose={() => {setTaskListShown(false)}} inspectionId={inspection?.id} />
            </div>
            <SettingsDialog open={settingsDialogOpen} onClose={() => {setSettingsDialogOpen(false)}}/>

            <Snackbar open={showRefreshSuccess}  anchorOrigin={{vertical: 'top',horizontal:'right' }} autoHideDuration={3000} onClose={hideRefreshSuccess}>
                <Alert style={{backgroundColor:'rgba(255,255,255,0.9)', color:'black', fontWeight:'bold', backdropFilter: 'blur(2px)'}} variant={"filled"} onClose={hideRefreshSuccess} severity="success">
                    Prüflingsliste erfolgreich aktualisiert
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoadingIndicator}
                // onClick={handleClose}
            >
                <div style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
                    <Typography style={{display:'block', color: 'white', paddingBottom: 10}}>Lade Prüfungsdaten...</Typography>
                    <CircularProgress style={{color: 'white'}} />
                </div>

            </Backdrop>
        </MuiThemeProvider>
    );
}


export default App;
