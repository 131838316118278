import Dut from './Dut';

export default class DutCategory {
    /**
     * @param id {number}
     * @param name {string}
     * @param duts {Dut[]}
     */
    constructor(id, name, duts) {
        this._id = id;
        this._name = name;
        this._duts = duts;
    }

    static fromResponse(data) {
        return new DutCategory(
            data.id,
            data['name'],
            data['devices'].map((dutData) => Dut.fromResponse(dutData))
        );
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @returns {Dut[]}
     */
    get duts() {
        return this._duts;
    }
}