import React from "react";
import {Button, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {authService} from '../services/AuthService'
import PropTypes from "prop-types";
import 'animate.css/animate.css'
import {Alert} from "@material-ui/lab";
import preval from 'preval.macro'



class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            error: false
        }
    }

    eventTest = (event) => {
        event.preventDefault();
        authService.login(this.state.email, this.state.password).then(() => {
            this.props.onLogin();
        }).catch(reason => {
            this.setState({
                error: true
            });
            // let bThis = this;
            // setTimeout(function () {
            //     bThis.setState({
            //         error: false
            //     })
            // }, 3000);
            console.log(reason);
        });
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false
        })
    }

    render() {
        const paperStyle = {padding: 20, width: 280, margin: "20px auto"}
        const btnStyle = {margin: '8px 0'}
        const errorStyle = {border: 'rgba(255,0,0,0.3) solid 1px'}
        const versionFooterStyle = {fontSize: '6pt', color:'grey'}

        const buildDate = preval`module.exports = new Date(new Date().getTime() + 3600000).toLocaleString('de-DE');`

        return (
            <>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent={'center'}
                      style={{minHeight: '100vh', backgroundColor: '#0F385F'}}>
                    <Paper className={this.state.error ? "animate__animated animate__shakeX" : ""} elevation={4} style={paperStyle} >
                        <Grid align='center'>
                            <img style={{width: '220px', paddingBottom: 20}} src={'/img/BFA-Logo.png'} alt={'BfA Logo'}/>
                            <h4 style={{color: 'red'}}>New Version - Beta</h4>
                            <p style={{color: 'red'}}>Stand: {buildDate}</p>
                            {
                                this.state.error &&
                                <Alert style={errorStyle} severity="error">Login fehlgeschlagen!</Alert>
                            }
                            <h2>Login</h2>
                        </Grid>
                        <form noValidate onSubmit={this.eventTest}>
                            <TextField label='E-Mail' name='email' onChange={this.onChange}
                                       placeholder='E-Mail-Adresse eingeben' fullWidth required/>
                            <TextField name='password' onChange={this.onChange} style={{marginBottom: 50}} label='Passwort'
                                       placeholder='Passwort eingeben' type='password' fullWidth required/>

                            <Button type='submit' color='secondary' variant="contained"
                                    style={btnStyle} fullWidth>Login</Button>
                        </form>
                        <Typography style={versionFooterStyle} align={"center"}>Version <b>{process.env.REACT_APP_VERSION}</b> ({buildDate})</Typography>
                    </Paper>
                </Grid>
            </>
        )
    }
}

Login.propTypes = {
    onLogin: PropTypes.func.isRequired
}
export default Login;