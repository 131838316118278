import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";

import React from "react";
import PropTypes from "prop-types";
import {Check} from "@material-ui/icons";


export default function SwitchInspectionDialog(props) {

    const renderListItem = (inspection) => {
        let isCurrent = inspection.id === props.inspection.id;
        return (
            <>
                <ListItem button onClick={() => {
                    props.didSelectInspection(inspection);
                    //props.onFacilityTypeSelected(facilityType);
                }}>
                    <ListItemText primary={inspection.facility.facilityTypes[0]?.name} />
                    <ListItemSecondaryAction>
                        { isCurrent && (
                            <Check />
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
            </>
        )
    }


    const facilityListItems = props.inspection?.otherInspections.map((inspection) => {
        return renderListItem(inspection)
    });

    return (
        <Dialog open={props.open}>
            <DialogTitle>Zu anderer Prüfung wechseln</DialogTitle>
            <DialogContent>
                <List component="nav">
                    {facilityListItems}
                </List>
            </DialogContent>
        </Dialog>
    )
}

SwitchInspectionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    inspection: PropTypes.object.isRequired,
    didSelectInspection: PropTypes.func.isRequired
}

